import { NodeViewWrapper } from '@tiptap/react';
import React, { useEffect } from 'react';
import { api } from '../../../../api';
import { Button, Popover, Spin, Tooltip } from 'antd';
import { Resizable } from 're-resizable';
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
} from '@ant-design/icons';
import axios from 'axios';

export default (props: any) => {
  const [blob, setBlob]: any = React.useState(null);
  const [width, setWidth] = React.useState(500);
  const [height, setHeight] = React.useState(500);
  const [ext, setExt] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  async function getBlob() {
    try {
      if (props.node.attrs.base64) {
        const response = await fetch(props.node.attrs.base64);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const ext: any = response?.headers
          .get('content-type')
          ?.toString()
          .split('/')[1];
        setBlob(url);
        setExt(ext);
        setLoading(false);
      } else {
        // const response: any = await api.get(
        //   `${import.meta.env.VITE_API_URL}/media/${props.node.attrs.imageKey}`,
        //   {
        //     responseType: 'blob',
        //   }
        // );
        const responsePresignedUrl = await api.get(
          `${import.meta.env.VITE_API_URL}/media/${props.node.attrs.imageKey}/presigned`,
        );

        const response = await axios.get(
          responsePresignedUrl.data,
          {
            responseType: 'blob',
          }
        );


        if (response.status === 404) {
          setBlob(null);
          setError(true);
          setLoading(false);
          return;
        }
        const url = URL.createObjectURL(new Blob([response.data]));
        const ext = response.headers['content-type'].split('/')[1];
        setExt(ext);
        setBlob(url);
        setLoading(false);
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        setBlob(null);
        setError(true);
        setLoading(false);
        return;
      }
    }
  }

  useEffect(() => {
    if (!blob) {
      getBlob();
    }
  }, []);

  const onResize = (a: any, b: any, c: any, d: any) => {
    setHeight(height + d.height);
    setWidth(width + d.width);
    props.updateAttributes({
      width: width + d.width,
      height: height + d.height,
      previewBlob: props.node.attrs.previewBlob,
    });
  };

  const handleImageLoad = (event: any) => {
    const { naturalWidth, naturalHeight } = event.target;
    if (!props.node.attrs.width && !props.node.attrs.height) {
      props.updateAttributes({
        width: naturalWidth,
        height: naturalHeight,
        previewBlob: props.node.attrs.base64,
      });
      setWidth(naturalWidth);
      setHeight(naturalHeight);
    } else {
      setWidth(props.node.attrs.width);
      setHeight(props.node.attrs.height);
    }
    URL.revokeObjectURL(blob);
  };

  return (
    <NodeViewWrapper className='auth-image'>
      {loading && !error && <Spin />}
      {error && (
        <div style={{ color: 'orange' }}>
          <ExclamationCircleOutlined /> Image not found
        </div>
      )}
      {blob && (
        <Resizable
          style={{ margin: '.5rem 0' }}
          onResizeStop={onResize}
          lockAspectRatio
          size={{
            width,
            height,
          }}
        >
          <Popover
            trigger={'click'}
            content={
              <div style={{ display: 'flex', gap: '.5rem' }}>
                <Tooltip title='Download'>
                  <Button
                    size='small'
                    type='primary'
                    title='Download'
                    onClick={(e) => {
                      e.stopPropagation();
                      const link = document.createElement('a');
                      link.href = blob;
                      link.download = `image-${Date.now()}.${ext}`;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }}
                  >
                    <DownloadOutlined />
                  </Button>
                </Tooltip>
              </div>
            }
          >
            <img
              src={blob}
              alt='img'
              style={{ width: '100%', height: '100%', borderRadius: '8px' }}
              onLoad={handleImageLoad}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Popover>
        </Resizable>
      )}
    </NodeViewWrapper>
  );
};
