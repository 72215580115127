import React, { useEffect } from 'react';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  MoreOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Dropdown,
  Menu,
  message,
  Popconfirm,
  Select,
  Space,
  TableColumnsType,
  Tag,
} from 'antd';
import { Button, Card, DatePicker, Input, Modal, Table } from 'antd';
import Text from 'antd/es/typography/Text';
import Editor from '@/components/Editor/index3';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '@/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import QuickChangeAssignee from '@/components/QuickChangeAssignee';
import AddOrCreateTask from './addOrCreateTask';
import { useUserStore } from '@/state/userState';

const Quests: React.FC = () => {
  const [showCreateTaskModal, setShowCreateTaskModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const { projectKey } = useParams();

  const navigate = useNavigate();

  const [selectedQuestId, setSelectedQuestId] = React.useState(null);
  const [label, setLabel] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [descriptionOne, setDescriptionOne] = React.useState('');
  const [startDate, setStartDate]: any = React.useState();
  const [endDate, setEndDate]: any = React.useState();
  const [questTasks, setQuestTasks] = React.useState([] as any[]);
  const [showAddTask, setShowAddTask] = React.useState(false);
  const [showAddOrCreateTask, setShowAddOrCreateTask] = React.useState(false);

  const [filter, setFilter] = React.useState('active');

  const [editorOpen, setEditorOpen] = React.useState(false);

  const queryClient = useQueryClient();

  const { user } = useUserStore();

  const { data, refetch, isFetching, isLoading } = useQuery({
    queryKey: ['quests', projectKey],
    queryFn: async () => {
      const { data } = await api.get(
        `/api/projects/${projectKey}/quests?filter=${filter}`
      );
      return data;
    },
  });

  const getQuestTasks = async (questId: string) => {
    const { data } = await api.get(`/api/quests/${questId}/tasks`);
    setQuestTasks(data);
  };

  useEffect(() => {
    refetch();
  }, [filter]);

  const createQuest = async () => {
    setLoading(true);
    try {
      if (selectedQuestId) {
        await api.put(`/api/quests/${selectedQuestId}`, {
          label,
          description: descriptionOne,
          startDate: startDate ? startDate.format('YYYY-MM-DD') : undefined,
          endDate: endDate ? endDate.format('YYYY-MM-DD') : undefined,
        });
        messageApi.success('Quest updated successfully');
      } else {
        await api.post(`/api/projects/${projectKey}/quests`, {
          label,
          description,
          startDate: startDate ? startDate.format('YYYY-MM-DD') : undefined,
          endDate: endDate ? endDate.format('YYYY-MM-DD') : undefined,
        });
        messageApi.success('Quest created successfully');
      }
      await refetch();
      setLabel('');
      setDescription('');
      setStartDate(undefined);
      setEndDate(undefined);
      setQuestTasks([]);
      setShowCreateTaskModal(false);
      setSelectedQuestId(null);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const columns: TableColumnsType<any> = [
    { title: 'Label', dataIndex: 'label', key: 'label' },
    {
      title: 'Tasks Completed',
      dataIndex: 'completed',
      key: 'completed',
      render(value, record, index) {
        return (
          <>
            {value} / {record.allTasks}
          </>
        );
      },
    },
    {
      title: 'Due In',
      dataIndex: 'dueIn',
      sorter: (a, b) => a.dueIn - b.dueIn,
      key: 'dueIn',
      render(value, record, index) {
        if (record.endDate) {
          return <>{value} days</>;
        }
        return <>/</>;
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (text) => {
        if (text) {
          return <>{dayjs(text).format('DD.MM.YYYY')}</>;
        }
        return <>/</>;
      },
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (text) => {
        if (text) {
          return <>{dayjs(text).format('DD.MM.YYYY')}</>;
        }
        return <>/</>;
      },
    },
    filter === 'completed' || filter === 'all'
      ? {
        title: 'Completed',
        sorter: (a, b) =>
          new Date(a.completedAt).getTime() -
          new Date(b.completedAt).getTime(),
        dataIndex: 'completedAt',
        key: 'completedAt',
        render: (text) => {
          if (text) {
            return <>{dayjs(text).format('DD.MM.YYYY')}</>;
          }
          return <>/</>;
        },
      }
      : {},
    // {
    //   title: 'Action',
    //   key: 'operation',
    //   render: () => (
    //     <Space size='middle'>
    //       <Dropdown menu={{ items: [] }}>
    //         <a>
    //           Add <DownOutlined />
    //         </a>
    //       </Dropdown>
    //       <Dropdown menu={{ items: [] }}>
    //         <a>
    //           More <DownOutlined />
    //         </a>
    //       </Dropdown>
    //     </Space>
    //   ),
    // },
  ];

  useEffect(() => {
    if (selectedQuestId) {
      getQuestTasks(selectedQuestId);
    }
  }, [selectedQuestId]);

  const questPerms = {
    createOrUpdateOrDelete: user?.isAdmin || user?.isManager,
    read: true,
  };

  return (
    <>
      {contextHolder}

      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <Space>
          Show:
          <Select
            defaultValue={'active'}
            value={filter}
            style={{ width: '150px' }}
            onChange={(value) => {
              setFilter(value);
            }}
          >
            <Select.Option value='all'>All</Select.Option>
            <Select.Option value='active'>Active</Select.Option>
            <Select.Option value='completed'>Completed</Select.Option>
          </Select>
          {questPerms.createOrUpdateOrDelete && (
            <Button
              onClick={() => {
                setShowCreateTaskModal(true);
              }}
              icon={<PlusCircleOutlined />}
            >
              Create new quest
            </Button>
          )}
        </Space>
      </div>
      <br />
      <Table
        loading={isFetching || isLoading}
        columns={columns}
        size='small'
        dataSource={data}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              setSelectedQuestId(record.id);
              setLabel(record.label);
              setDescription(record.description);
              setDescriptionOne(record.description);
              setStartDate(
                record.startDate
                  ? dayjs(record.startDate, 'YYYY-MM-DD')
                  : undefined
              );
              setEndDate(
                record.endDate ? dayjs(record.endDate, 'YYYY-MM-DD') : undefined
              );
              setShowCreateTaskModal(true);
              setShowAddTask(true);
            },
          };
        }}
      />
      {
        showCreateTaskModal && (
          <Modal
            open={showCreateTaskModal}
            width={'60vw'}
            title={<>{selectedQuestId ? 'Update' : 'Create new'} quest</>}
            style={{ top: 20 }}
            confirmLoading={loading}
            onCancel={() => {
              setLabel('');
              setDescription('');
              setDescriptionOne('');
              setStartDate(undefined);
              setEndDate(undefined);
              setQuestTasks([]);
              setShowCreateTaskModal(false);
              setSelectedQuestId(null);
            }}
            onClose={() => {
              setLabel('');
              setDescription('');
              setDescriptionOne('');
              setStartDate(undefined);
              setEndDate(undefined);
              setQuestTasks([]);
              setShowCreateTaskModal(false);
            }}
            okText={selectedQuestId ? 'Update' : 'Create'}
            onOk={
              questPerms.createOrUpdateOrDelete
                ? () => {
                  createQuest();
                }
                : () => {
                  setLabel('');
                  setDescription('');
                  setDescriptionOne('');
                  setStartDate(undefined);
                  setEndDate(undefined);
                  setQuestTasks([]);
                  setShowCreateTaskModal(false);
                }
            }
          >
            <div style={{ marginTop: '1rem' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div></div>
                <div>
                  {selectedQuestId && questPerms.createOrUpdateOrDelete && (
                    <Space>
                      <Popconfirm
                        title='Are you sure you want to complete this quest?'
                        onConfirm={async () => {
                          await api.put(`/api/quests/${selectedQuestId}/complete`);
                          await refetch();
                          setLabel('');
                          setDescription('');
                          setStartDate(undefined);
                          setEndDate(undefined);
                          setQuestTasks([]);
                          setShowCreateTaskModal(false);
                          setSelectedQuestId(null);
                        }}
                      >
                        <Button size='small'>
                          <CheckCircleOutlined />
                        </Button>
                      </Popconfirm>
                      <Popconfirm
                        title='Are you sure you want to delete this quest?'
                        description="This action can't be undone"
                        onConfirm={async () => {
                          await api.delete(`/api/quests/${selectedQuestId}`);
                          await refetch();
                          setLabel('');
                          setDescription('');
                          setDescriptionOne('');
                          setStartDate(undefined);
                          setEndDate(undefined);
                          setQuestTasks([]);
                          setShowCreateTaskModal(false);
                          setSelectedQuestId(null);
                        }}
                      >
                        <Button danger size='small'>
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    </Space>
                  )}
                </div>
              </div>
              <div>
                <Input
                  value={label}
                  disabled={!questPerms.createOrUpdateOrDelete}
                  onChange={(e) => {
                    setLabel(e.target.value);
                  }}
                  placeholder='Quest name'
                />
              </div>
              <br />
              <div style={{ display: 'flex', gap: '1rem' }}>
                <div>
                  <DatePicker
                    format={{
                      format: 'DD.MM.YYYY',
                      type: 'mask',
                    }}
                    value={startDate ? dayjs(startDate) : null}
                    placeholder='Start Date'
                    disabled={!questPerms.createOrUpdateOrDelete}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    maxDate={endDate ? endDate : undefined}
                  />
                </div>
                <div>
                  <DatePicker
                    format={{
                      format: 'DD.MM.YYYY',
                      type: 'mask',
                    }}
                    minDate={startDate ? startDate : undefined}
                    disabled={!questPerms.createOrUpdateOrDelete}
                    value={endDate ? dayjs(endDate) : null}
                    placeholder='End Date'
                    onChange={(date) => {
                      setEndDate(date);
                    }}
                  />
                </div>
              </div>
              <br />
              <p>Summary</p>
              <div
                onClick={() => {
                  setEditorOpen(true);
                }}
                onBlur={() => {
                  setEditorOpen(false);
                }}
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '8px',
                  padding: '5px',
                }}
              >
                <Editor
                  defaultContent={description}
                  onChange={setDescriptionOne}
                  editable={editorOpen && questPerms.createOrUpdateOrDelete}
                  disableMentions
                />
              </div>
              <br />
              {showAddTask &&
                questPerms.createOrUpdateOrDelete &&
                selectedQuestId && (
                  <Button
                    icon={<PlusCircleFilled />}
                    onClick={() => {
                      setShowAddOrCreateTask(!showAddOrCreateTask);
                      // show create or add new task;
                    }}
                  >
                    Add task
                  </Button>
                )}
              {showAddTask && questPerms.createOrUpdateOrDelete && <br />}
              {showAddOrCreateTask &&
                selectedQuestId &&
                questPerms.createOrUpdateOrDelete && (
                  <>
                    <br />
                    <AddOrCreateTask
                      questId={selectedQuestId}
                      refetch={getQuestTasks}
                    />
                    <br />
                  </>
                )}
              {showAddTask && questPerms.createOrUpdateOrDelete && <br />}
              {selectedQuestId && (
                <Table
                  size='small'
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        navigate(`?openTask=${record.key}`);
                      },
                    };
                  }}
                  columns={[
                    { title: 'Board', dataIndex: 'boardLabel', key: 'description' },
                    { title: 'Key', dataIndex: 'key', key: 'taskKey' },
                    { title: 'Task', dataIndex: 'label', key: 'taskLabel' },
                    {
                      title: 'Status',
                      dataIndex: 'columnLabel',
                      key: 'dueDate',
                      render(value, record, index) {
                        return (
                          <>
                            <Tag>{record.columnType}</Tag>
                            {value}
                          </>
                        );
                      },
                    },
                    {
                      title: 'Asignee',
                      dataIndex: 'dueDate',
                      key: 'dueDate',
                      render(value, record, index) {
                        return (
                          <div style={{ textAlign: 'center' }}>
                            <QuickChangeAssignee
                              taskId={record.taskId}
                              currentAssigneeId={record.assigneeId}
                              projectKey={projectKey ?? ''}
                              avatarSize={33}
                              disabled
                              showToolTip={true}
                            />
                          </div>
                        );
                      },
                    },
                    {
                      title: '',
                      dataIndex: 'id',
                      key: 'id',
                      render(value, record, index) {
                        return (
                          <Popconfirm
                            title='Are you sure you want to detach this task?'
                            onConfirm={async (e) => {
                              e?.stopPropagation();
                              await api.put(`/api/tasks/${record.taskId}`, {
                                questId: 'remove',
                              });
                              await getQuestTasks(selectedQuestId);
                              await queryClient.invalidateQueries({
                                queryKey: ['availableTasks', selectedQuestId],
                              });
                            }}
                            okText='Yes'
                            cancelText='No'
                          >
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              type='link'
                              danger
                            >
                              Detach
                            </Button>
                          </Popconfirm>
                        );
                      },
                    },
                  ]}
                  dataSource={questTasks}
                />
              )}
            </div>
          </Modal>
        )
      }
    </>
  );
};

export default Quests;
