import { api } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { Button, Segmented, Table } from 'antd';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { useEffect, useState } from 'react';
import SpecificAdminTimesheet from './specificTimesheet';

dayjs.extend(isoWeek);

export default function AdminTimesheets() {
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [showSpecificModal, setShowSpecificModal] = useState(false);
  const [showByType, setShowByType] = useState<'week' | 'month'>('month');

  const [selectedUser, setSelectedUser] = useState('');
  const [selectedUserStart, setSelectedUserStart] = useState('');
  const [selectedUserEnd, setSelectedUserEnd] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');

  /**
   * Whenever `showByType` changes, we reset to the current week or the current month.
   * This ensures toggling does not depend on the previous date range.
   */
  useEffect(() => {
    if (showByType === 'week') {
      // Monday -> Sunday of the current week
      const startOfCurrentWeek = dayjs().startOf('isoWeek').isoWeekday(1); // Monday
      const endOfCurrentWeek = dayjs().startOf('isoWeek').isoWeekday(7);   // Sunday
      setStartDate(startOfCurrentWeek);
      setEndDate(endOfCurrentWeek);
    } else {
      // Entire current month
      setStartDate(dayjs().startOf('month'));
      setEndDate(dayjs().endOf('month'));
    }
    resetSpecific();
  }, [showByType]);

  async function getTimesheets() {
    const response = await api.get(
      `/api/admin/timesheets?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}`
    );
    return response.data;
  }

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['timesheets', 'admin', startDate, endDate, showByType],
    queryFn: getTimesheets,
  });

  /**
   * Navigate to the next or previous "period" (week or month).
   */
  const nextPeriod = async () => {
    resetSpecific();
    if (showByType === 'week') {
      // Move one week forward
      setStartDate((prev) => prev.add(1, 'week'));
      setEndDate((prev) => prev.add(1, 'week'));
    } else {
      // Move one month forward
      setStartDate((prev) => prev.add(1, 'month').startOf('month'));
      setEndDate((prev) => prev.add(1, 'month').endOf('month'));
    }
    await refetch();
  };

  const previousPeriod = async () => {
    resetSpecific();
    if (showByType === 'week') {
      // Move one week backward
      setStartDate((prev) => prev.subtract(1, 'week'));
      setEndDate((prev) => prev.subtract(1, 'week'));
    } else {
      // Move one month backward
      setStartDate((prev) => prev.subtract(1, 'month').startOf('month'));
      setEndDate((prev) => prev.subtract(1, 'month').endOf('month'));
    }
    await refetch();
  };

  const resetSpecific = () => {
    setShowSpecificModal(false);
    setSelectedUser('');
    setSelectedUserStart('');
    setSelectedUserEnd('');
    setSelectedUserName('');
  };

  const columns = [
    {
      title: 'User',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Hours',
      dataIndex: 'hoursWorked',
      key: 'hoursWorked',
      render: (value: string) =>
        // Just a small fix for 'xx:0h' -> 'xx:00h'
        `${value?.endsWith(':0h') ? value.replace('0h', '00h') : value}`,
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: '1rem' }}>
        View:{' '}
        <Segmented<'week' | 'month'>
          options={['week', 'month']}
          value={showByType}
          onChange={(value) => setShowByType(value)}
        />
      </div>

      <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <Button onClick={previousPeriod}>Previous</Button>
        <Title level={4} style={{ marginTop: '.5rem' }}>
          {startDate.format('DD MMM YYYY')} - {endDate.format('DD MMM YYYY')}
        </Title>
        <Button onClick={nextPeriod}>Next</Button>
      </div>

      <div style={{ display: 'flex', gap: '1rem' }}>
        <div style={{ flex: 2 }}>
          <Table
            dataSource={data}
            columns={columns}
            rowKey='id'
            loading={isLoading}
            pagination={false}
            onRow={(record: any) => {
              return {
                onClick: () => {
                  setShowSpecificModal(true);
                  setSelectedUser(record.userId);
                  setSelectedUserName(record.fullName);
                  setSelectedUserStart(startDate.format('YYYY-MM-DD'));
                  setSelectedUserEnd(endDate.format('YYYY-MM-DD'));
                },
              };
            }}
          />
        </div>

        <div style={{ flex: 3 }}>
          {showSpecificModal && (
            <SpecificAdminTimesheet
              userId={selectedUser}
              start={selectedUserStart}
              end={selectedUserEnd}
              selectedUserName={selectedUserName}
            />
          )}
        </div>
      </div>
    </div>
  );
}
