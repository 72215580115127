import { Avatar, Image, Spin, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import Text from 'antd/es/typography/Text';
import axios from 'axios';

export default function UserAvatar({
  token,
  size = 40,
  showName,
  showToolTip,
  userName,
}: {
  token: string | null;
  size: number;
  showName?: boolean;
  showToolTip?: boolean;
  userName?: string;
}) {
  const [src, setSrc] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  async function getAvatar() {
    setLoading(true);
    try {
     
      const response = await axios.get(
        `https://questiro.eu-central-1.linodeobjects.com/user-avatar/${token}`,
        {
          responseType: 'blob',
        }
      );

      const reader = new FileReader();
      reader.onloadend = () => {
        setSrc(reader.result as string);
        setLoading(false);
      };
      reader.readAsDataURL(response.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (token && token !== 'unassigned') {
      getAvatar();
    }
  }, [token]);

  if (!token || loading) return <Avatar size={size} icon={<UserOutlined />} />;

  return (
    <>
      {!src && <Avatar size={size} icon={<UserOutlined />} />}
      {src && (
        <Tooltip title={showToolTip ? userName : undefined}>
          <Avatar size={size} src={src} alt='User avatar or image' />
          {showName && <Text style={{ marginLeft: '0.5rem' }}>{userName}</Text>}
        </Tooltip>
      )}
    </>
  );
}
