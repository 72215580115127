import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useProjectStore } from '../../../state/projectState';
import { api } from '../../../api';
import {
  Skeleton,
  Table,
  Divider,
  Select,
  Button,
  Avatar,
  Tag,
  Collapse,
  DatePicker,
} from 'antd';
import { ColumnType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import Text from 'antd/es/typography/Text';

import useMessage from 'antd/es/message/useMessage';
import TaskDetailsModal2 from '../../../modals/taskModal';
import { useNavigate, useParams } from 'react-router-dom';
import QuickChangeAssignee from '../../../components/QuickChangeAssignee';
import { TableRowSelection } from 'antd/es/table/interface';
import BulkUpdateTask from './components/BulkUpdateTask';
import UserAvatar from '../../../components/UserAvatar';
import { ClearOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getTaskPriorityIcon, getTaskTypeIcon } from '@/utils';
import { RelativeTime } from '@/components/RelativeTime';
import dayjs from 'dayjs';
import { sseClient } from '@/api/realtime';

function ProjectOverview_List() {
  const project = useProjectStore((state) => state.project);
  const [taskState, setTaskState] = useState<'all' | 'active' | 'archived'>(
    'active'
  );

  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);

  const [query, setQuery]: any = useState({
    dueDate: null,
  });

  const [message, messageContext] = useMessage();
  const { taskKey } = useParams();
  const navigate = useNavigate();

  const getOverview = async (
    taskState: 'all' | 'active' | 'archived' | 'completed'
  ) => {
    if (!project) return;
    const queryStr = encodeURIComponent(JSON.stringify(query));

    const response = await api.get(
      `/api/projects/${project.id}/overview/list${taskState
        ? `?include=${taskState}&skip=${skip}&limit=${limit}&q=${queryStr}`
        : ''
      }`
    );
    if (taskKey) {
      const task = response.data.tasks.find((t: any) => t.taskKey === taskKey);
      setActiveTask(task);
      setModalOpen(true);
    }

    return response.data;
  };

  const {
    data: overviewData,
    isLoading: overviewIsLoading,
    isFetching: overviewIsFetching,
    refetch: refetchOverview,
  } = useQuery({
    queryKey: ['overview', project?.id],
    queryFn: () => {
      return getOverview(taskState);
    },
  });

  const handleTaskUpdated = (event: any) => {
    const { payload } = JSON.parse(JSON.parse(event.data));

    queryClient.setQueryData(['overview', project?.id], (data: any) => {
      return {
        ...data,
        tasks: Array.from(
          data.tasks.map((t: any) => {
            if (t.id === payload.id) {
              return { ...t, ...payload };
            }
            return t;
          })
        ),
      };
    });
  };

  useEffect(() => {
    if (!overviewData) return;

    sseClient.addEventListener('task_updated', handleTaskUpdated);

    return () => {
      sseClient.removeEventListener('task_updated', handleTaskUpdated);
    };
  }, [overviewData]);

  async function getBoards() {
    const { data } = await api.get(`/api/projects/${project?.key}/boards`);
    return data;
  }

  async function getQuestList() {
    const { data } = await api.get(`/api/projects/${project?.key}/quests-list`);
    return data;
  }

  const {
    data: boardData,
    isLoading: boardIsLoading,
    refetch,
  } = useQuery({
    queryKey: ['boards', project?.key],
    queryFn: getBoards,
    staleTime: 1000 * 60 * 5,
  });

  const { data: quests, isLoading: questsLoading } = useQuery({
    queryKey: ['quests-list', project?.key],
    queryFn: getQuestList,
    staleTime: Infinity,
  });

  async function getPriorities() {
    const { data } = await api.get(`/api/priority`);
    return data;
  }

  const { data: priorities } = useQuery({
    queryKey: ['priority'],
    queryFn: getPriorities,
    staleTime: Infinity,
  });

  const [boardsToFilter, setBoardsToFilter] = useState([]) as any;
  const [statusToFilter, setStatusToFilter] = useState([]) as any;
  const [typeToFilter, setTypeToFilter] = useState([]) as any;
  const [assigneeToFilter, setAssigneeToFilter] = useState([]) as any;
  const [priorityFilter, setPriorityFilter] = useState([]) as any;
  const [dueDateFilter, setDueDateFilter] = useState(null) as any;
  const [dueDateValue, setDueDateValue] = useState<string | null>(null);
  const [customDate, setCustomDate] = useState(null) as any;
  const [questsToFilter, setQuestsToFilter] = useState([]) as any;

  const [boardKey, setBoardKey] = useState<string | null>(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const onSelectChange = (newSelectedRowKeys: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<any> | undefined = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps(record) {
      return {
        disabled: !!record.archivedAt || !!record.completedAt,
      };
    },
  };

  const [activeTask, setActiveTask] = useState<any>(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (boardsToFilter.length > 0) {
      setQuery((prev: any) => ({
        ...prev,
        boardIds: boardsToFilter,
      }));
    } else if (query['boardIds'] && boardsToFilter.length === 0) {
      setQuery((prev: any) => ({
        ...prev,
        boardIds: null,
      }));
    }
    if (statusToFilter.length > 0) {
      setQuery((prev: any) => ({
        ...prev,
        columnTypeIds: statusToFilter,
      }));
    } else if (query['columnTypeIds'] && statusToFilter.length === 0) {
      setQuery((prev: any) => ({
        ...prev,
        columnTypeIds: null,
      }));
    }
    if (typeToFilter.length > 0) {
      setQuery((prev: any) => ({
        ...prev,
        taskTypeIds: typeToFilter,
      }));
    } else if (query['taskTypeIds'] && typeToFilter.length === 0) {
      setQuery((prev: any) => ({
        ...prev,
        taskTypeIds: null,
      }));
    }
    if (assigneeToFilter.length > 0) {
      setQuery((prev: any) => ({
        ...prev,
        assigneeIds: assigneeToFilter,
      }));
    } else if (query['assigneeIds'] && assigneeToFilter.length === 0) {
      setQuery((prev: any) => ({
        ...prev,
        assigneeIds: null,
      }));
    }
    if (priorityFilter.length > 0) {
      setQuery((prev: any) => ({
        ...prev,
        priorityIds: priorityFilter,
      }));
    } else if (query['priorityIds'] && priorityFilter.length === 0) {
      setQuery((prev: any) => ({
        ...prev,
        priorityIds: null,
      }));
    }
    if (!dueDateValue) {
      setQuery((prev: any) => ({
        ...prev,
        dueDate: null,
      }));
    }
    if (questsToFilter.length > 0) {
      setQuery((prev: any) => ({
        ...prev,
        questIds: questsToFilter,
      }));
    } else if (query['questIds'] && questsToFilter.length === 0) {
      setQuery((prev: any) => ({
        ...prev,
        questIds: null,
      }));
    }

  }, [
    boardsToFilter,
    statusToFilter,
    typeToFilter,
    assigneeToFilter,
    priorityFilter,
    dueDateValue,
    questsToFilter
  ]);

  useEffect(() => {
    refetchOverview();
  }, [query]);

  useEffect(() => {
    if (dueDateFilter) {
      setQuery((prev: any) => ({
        ...prev,
        dueDate: dueDateFilter,
      }));
    } else if (query['dueDate'] && !dueDateFilter) {
      setQuery((prev: any) => ({
        ...prev,
        dueDate: null,
      }));
    }
  }, [dueDateFilter]);

  useEffect(() => {
    //reset
    setBoardsToFilter([]);
    setStatusToFilter([]);
    setTypeToFilter([]);
    setAssigneeToFilter([]);
    setSelectedRowKeys([]);
    setPriorityFilter([]);
    setBoardKey(null);
    setDueDateFilter(null);
    setDueDateValue(null);
  }, [project?.key]);

  useEffect(() => {
    refetchOverview();
  }, [skip, limit, taskState]);

  const queryClient = useQueryClient();

  if (boardIsLoading || !project) {
    return <Skeleton active />;
  }

  const assignees: any = [
    {
      value: null,
      label: 'Unassigned',
    },
    ...(overviewData
      ? overviewData?.users.map((u: any) => ({
        value: u.id,
        label: u.fullName,
        avatar: u.avatar,
      }))
      : []),
  ];

  const columns: ColumnType<any>[] = [
    {
      title: 'Board',
      dataIndex: 'boardLabel',
      key: 'board',
      sorter: (a, b) => a.boardLabel.localeCompare(b.boardLabel),
      width: 130,
    },
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      width: 150,
      render(value, record, index) {
        return (
          <Button
            type='link'
            onClick={() => {
              // setActiveTask(record);
              // setModalOpen(true);
              navigate(
                `/project/${project.key}/overview?openTask=${record.key}`,
                {
                  replace: true,
                }
              );
            }}
          >
            {value}
          </Button>
        );
      },
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      width: 220,
      render(value, record, index) {
        return (
          <Text
            editable={{
              onChange: async (newLabel) => {
                try {
                  queryClient.setQueryData(
                    ['overview', project.id],
                    (data: any) => {
                      return {
                        ...data,
                        tasks: data.tasks.map((t: any) => {
                          if (t.id === record.id) {
                            return {
                              ...t,
                              label: newLabel,
                            };
                          }
                          return t;
                        }),
                      };
                    }
                  );
                  await api.put(`/api/tasks/${record.id}`, {
                    label: newLabel,
                  });

                  message.success('Task label updated!');
                } catch (error) {
                  message.error('Error updating task label');
                  queryClient.setQueryData(
                    ['overview', project.id],
                    (data: any) => {
                      return {
                        ...data,
                        tasks: data.tasks.map((t: any) => {
                          if (t.id === record.id) {
                            return {
                              ...t,
                              taskLabel: value,
                            };
                          }
                          return t;
                        }),
                      };
                    }
                  );
                  console.log(error);
                }
              },
            }}
          >
            {value}
          </Text>
        );
      },
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'columnCategoryLabel',
    //   key: 'columnCategoryLabel',
    // width: 150,
    //   sorter: (a, b) =>
    //     a.columnCategoryLabel.localeCompare(b.columnCategoryLabel),
    // },
    {
      title: 'Column',
      dataIndex: 'columnLabel',
      width: 150,
      key: 'column',
      render(value, record, index) {
        // get all columns for the board
        return (
          <Select
            disabled={!!record.archivedAt || !!record.completedAt}
            value={value}
            variant='borderless'
            style={{
              width: '100%',
            }}
            onChange={async (value, option: any) => {
              const dataToSend = {
                newColumnId: option.value,
                beforeId: 'first',
              };

              try {
                queryClient.setQueryData(
                  ['overview', project.id],
                  (data: any) => {
                    return {
                      ...data,
                      tasks: data.tasks.map((t: any) => {
                        if (t.id === record.id) {
                          return {
                            ...t,
                            columnId: option.value,
                            columnLabel: option.label,
                          };
                        }
                        return t;
                      }),
                    };
                  }
                );

                await api.post(`/api/tasks/${record.id}/move`, dataToSend);

                message.success('Task moved!');
              } catch (error) {
                message.error('Error moving task');
                queryClient.setQueryData(
                  ['overview', project.id],
                  (data: any) => {
                    return {
                      ...data,
                      tasks: data.tasks.map((t: any) => {
                        if (t.id === record.id) {
                          return {
                            ...t,
                            columnId: t.columnId,
                            columnLabel: t.columnLabel,
                          };
                        }
                        return t;
                      }),
                    };
                  }
                );
                console.log(error);
              }
            }}
            options={overviewData.columnsPerBoard
              .filter((c: { boardId: any }) => c.boardId === record.boardId)
              .sort(
                (a: { orderKey: number }, b: { orderKey: number }) =>
                  (a.orderKey > b.orderKey) as any
              )
              .map((c: { id: any; label: any }) => ({
                value: c.id,
                label: c.label,
              }))}
          />
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'taskTypeLabel',
      key: 'taskTypeLabel',
      width: 150,
      render(value, record, index) {
        // get all types from backend
        return (
          <Select
            value={value}
            disabled={!!record.archivedAt || !!record.completedAt}
            variant='borderless'
            style={{
              width: '100%',
            }}
            onChange={async (value, option: any) => {
              try {
                queryClient.setQueryData(
                  ['overview', project.id],
                  (data: any) => {
                    return {
                      ...data,
                      tasks: data.tasks.map((t: any) => {
                        if (t.id === record.id) {
                          return {
                            ...t,
                            taskTypeId: option.value,
                            taskTypeLabel: option.label,
                          };
                        }
                        return t;
                      }),
                    };
                  }
                );

                await api.put(`/api/tasks/${record.id}`, {
                  taskTypeId: option.value,
                });
                message.success('Task type updated!');
              } catch (error) {
                message.error('Error updating task type');
                queryClient.setQueryData(
                  ['overview', project.id],
                  (data: any) => {
                    return {
                      ...data,
                      tasks: data.tasks.map((t: any) => {
                        if (t.id === record.id) {
                          return {
                            ...t,
                            taskTypeId: t.taskTypeId,
                            taskTypeLabel: t.taskTypeLabel,
                          };
                        }
                        return t;
                      }),
                    };
                  }
                );
                console.log(error);
              }

              // refetchOverview();
            }}
            labelRender={(value: any) => {
              return (
                <>
                  {getTaskTypeIcon(value.value?.toLowerCase() as any)}{' '}
                  {value.value}
                </>
              );
            }}
            optionRender={(option: any) => (
              <div style={{ display: 'flex', gap: '.3rem' }}>
                {getTaskTypeIcon(option.label?.toLowerCase() as any)}{' '}
                {option.label}
              </div>
            )}
            options={[
              {
                value: 'ttkefwc4qtaaogcxrkyu98x0',
                taskTypeKey: 'task',
                label: 'Task',
              },
              {
                value: 'tzz06j7svw51umjdd0ov5ey8',
                taskTypeKey: 'bug',
                label: 'Bug',
              },
              {
                value: 'w38qzcuzxm81h4jarp4aog9g',
                taskTypeKey: 'story',
                label: 'Story',
              },
            ]}
          />
        );
      },
    },
    {
      title: 'Priority',
      dataIndex: 'priorityLabel',
      key: 'priorityLabel',
      width: 150,
      render(value, record, index) {
        // get all types from backend
        return (
          <Select
            value={value}
            disabled={!!record.archivedAt || !!record.completedAt}
            variant='borderless'
            style={{
              width: '100%',
            }}
            onChange={async (value) => {
              await api.put(`/api/tasks/${record.id}`, {
                priorityId: value,
              });

              queryClient.setQueryData(['overview', project.id], (data: any) => {
                return {
                  ...data,
                  tasks: data.tasks.map((t: any) => {
                    if (t.id === record.id) {
                      return {
                        ...t,
                        priorityId: value,
                        priorityLabel: priorities.find((p: any) => p.id === value)
                          .label,
                      };
                    }
                    return t;
                  }),
                };
              });

              message.success('Task priority updated!');
            }}
            labelRender={(value: any) => {
              return (
                <>
                  {getTaskPriorityIcon(value.value?.toLowerCase() as any)}{' '}
                  {value.value}
                </>
              );
            }}
            optionRender={(option: any) => (
              <div>
                {getTaskPriorityIcon(option.label?.toLowerCase() as any)}{' '}
                {option.label}
              </div>
            )}
            options={priorities?.map((p: any) => ({
              value: p.id,
              label: p.label,
            }))}
          />
        );
      },
    },
    {
      title: 'Assignee',
      dataIndex: 'assigneeId',
      key: 'assigneeId',
      width: 80,
      render(value, record, index) {
        return (
          <div style={{ textAlign: 'center' }}>
            <QuickChangeAssignee
              taskId={record.id}
              disabled={!!record.archivedAt || !!record.completedAt}
              currentAssigneeId={record.assigneeId}
              projectKey={project.key}
              avatarSize={33}
              showToolTip={true}
              onChangeAssignee={(newAssignee: any) => {
                try {
                  queryClient.setQueryData(
                    ['overview', project.id],
                    (data: any) => {
                      return {
                        ...data,
                        tasks: data.tasks.map((t: any) => {
                          if (t.id === record.id) {
                            return {
                              ...t,
                              assigneeId: newAssignee.value,
                              assigneeName: newAssignee.label,
                              assigneeAvatar: newAssignee.avatar,
                            };
                          }
                          return t;
                        }),
                      };
                    }
                  );

                  message.success('Task reassigned!');
                } catch (error) { }
                // refetchOverview();
              }}
              tooltipName={record.assigneeName ?? 'Unassigned'}
            />
          </div>
        );
      },
    },
    {
      title: 'Due',
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: 120,
      sorter: (a: any, b: any) =>
        new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
      render(value, record, index) {
        return (
          <DatePicker
            style={{ width: '100%' }}
            variant={'borderless'}
            format={'DD MMM YYYY'}
            value={
              record?.dueDate
                ? dayjs(dayjs(value.replace('Z', '')).format('DD MMM YYYY'))
                : undefined
            }
            onChange={async (value) => {
              try {
                try {
                  queryClient.setQueryData(
                    ['overview', project.id],
                    (data: any) => {
                      return {
                        ...data,
                        tasks: data.tasks.map((t: any) => {
                          if (t.id === record.id) {
                            return {
                              ...t,
                              dueDate: value
                                ? value.format('YYYY-MM-DD')
                                : null,
                            };
                          }
                          return t;
                        }),
                      };
                    }
                  );

                  await api.put(`/api/tasks/${record.id}`, {
                    dueDate: value ? value.format('YYYY-MM-DD') : null,
                  });

                  message.success('Task due date updated!');
                } catch (error) {
                  message.error('Error updating task due date');
                  queryClient.setQueryData(
                    ['overview', project.id],
                    (data: any) => {
                      return {
                        ...data,
                        tasks: data.tasks.map((t: any) => {
                          if (t.id === record.id) {
                            return {
                              ...t,
                              dueDate: record.dueDate,
                            };
                          }
                          return t;
                        }),
                      };
                    }
                  );
                  console.log(error);
                }
              } catch (error) {
                console.log(error);
              }
            }}
            showNow={false}
            presets={[
              {
                label: 'Today',
                value: dayjs().endOf('day'),
              },
              {
                label: 'Tomorrow',
                value: dayjs().add(1, 'day').endOf('day'),
              },
              {
                label: 'Next week',
                value: dayjs().add(1, 'week').endOf('day'),
              },
              {
                label: '2 weeks',
                value: dayjs().add(2, 'week').endOf('day'),
              },
              {
                label: '1 month',
                value: dayjs().add(1, 'month').endOf('day'),
              },
            ]}
          />
        );
      },
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      sorter: (a: any, b: any) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      render(value, record, index) {
        return (
          <div>
            <RelativeTime date={value} showHoursAndMinutes />
          </div>
        );
      },
    },
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 120,
      sorter: (a: any, b: any) =>
        new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
      render(value, record, index) {
        if (!value) return 'N/A';
        return (
          <div>
            <RelativeTime date={value} showHoursAndMinutes />
          </div>
        );
      },
    },
  ];

  const getActiveFilterCount = () => {
    return (
      boardsToFilter.length +
      statusToFilter.length +
      typeToFilter.length +
      priorityFilter.length +
      assigneeToFilter.length +
      (dueDateFilter ? 1 : 0) +
      questsToFilter.length
    );
  };

  const selectWidth = 150;

  return (
    <div>
      {messageContext}
      <Table
        size='small'
        bordered
        title={() => (
          <>
            <Collapse
              size='small'
              bordered={false}
              items={[
                {
                  key: '1',
                  label: (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                      }}
                    >
                      <div>
                        Filters{' '}
                        {getActiveFilterCount() ? (
                          <Tag> {getActiveFilterCount()}</Tag>
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        {getActiveFilterCount() > 0 && (
                          <Button
                            size='small'
                            type='text'
                            danger
                            onClick={(e) => {
                              e.stopPropagation();
                              setBoardsToFilter([]);
                              setStatusToFilter([]);
                              setTypeToFilter([]);
                              setAssigneeToFilter([]);
                              setPriorityFilter([]);
                              setDueDateFilter(null);
                              setDueDateValue(null);
                              setCustomDate(null);
                              refetchOverview();
                            }}
                          >
                            <ClearOutlined /> Clear Filters
                          </Button>
                        )}
                      </div>
                    </div>
                  ),
                  children: (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          gap: '1rem',
                          marginBottom: '1rem',
                          flexWrap: "wrap"
                        }}
                      >
                        <Select
                          title='Board'
                          value={boardsToFilter}
                          placeholder='Board'
                          mode='multiple'
                          allowClear
                          style={{ width: selectWidth }}
                          options={boardData?.map((board: any) => ({
                            key: board.key,
                            value: board.id,
                            label: board.label,
                          }))}
                          onChange={(value, option: any) => {
                            setBoardsToFilter(value);
                            setBoardKey(null);
                            setSelectedRowKeys([]);
                            if (value.length === 1) {
                              setBoardKey(option[0].key);
                            }
                            if (value.length === 0) {
                              setBoardKey(null);
                            }
                          }}
                        />
                        <Select
                          mode='multiple'
                          value={statusToFilter}
                          title='Status'
                          placeholder='Status'
                          style={{ width: selectWidth }}
                          allowClear
                          options={[
                            {
                              value: 'j82senyrwaaza0m8omtn6jgu',
                              label: 'To Do',
                            },
                            {
                              value: 'gt2i39u4ierremke4g926x0x',
                              label: 'In Progress',
                            },
                            {
                              value: 'b7p0jiayd4tjnp5zkw5qo06w',
                              label: 'Done',
                            },
                          ].map((board: any) => ({
                            value: board.value,
                            label: board.label,
                          }))}
                          onChange={(value, option) => {
                            setStatusToFilter(value);
                          }}
                        />
                        <Select
                          mode='multiple'
                          value={typeToFilter}
                          title='Type'
                          placeholder='Type'
                          allowClear
                          style={{ width: selectWidth }}
                          labelRender={(value: any) => {
                            return (
                              <>
                                {getTaskTypeIcon(
                                  value.value?.toLowerCase() as any
                                )}{' '}
                                {value.label}
                              </>
                            );
                          }}
                          optionRender={(option: any) => (
                            <div style={{ display: 'flex', gap: '.3rem' }}>
                              {getTaskTypeIcon(
                                option.label?.toLowerCase() as any
                              )}{' '}
                              {option.label}
                            </div>
                          )}
                          options={[
                            {
                              value: 'ttkefwc4qtaaogcxrkyu98x0',
                              label: 'Task',
                            },
                            {
                              value: 'tzz06j7svw51umjdd0ov5ey8',
                              label: 'Bug',
                            },
                            {
                              value: 'w38qzcuzxm81h4jarp4aog9g',
                              label: 'Story',
                            },
                          ].map((board: any) => ({
                            value: board.value,
                            label: board.label,
                          }))}
                          onChange={(value, option) => {
                            setTypeToFilter(value);
                          }}
                        />

                        <Select
                          mode='multiple'
                          value={priorityFilter}
                          title='Priority'
                          placeholder='Priority'
                          allowClear
                          style={{ width: selectWidth }}
                          labelRender={(value: any) => {
                            return (
                              <>
                                {getTaskPriorityIcon(
                                  value.label?.toLowerCase() as any
                                )}{' '}
                                {value.label}
                              </>
                            );
                          }}
                          optionRender={(option: any) => (
                            <div>
                              {getTaskPriorityIcon(
                                option.label?.toLowerCase() as any
                              )}{' '}
                              {option.label}
                            </div>
                          )}
                          options={priorities?.map((p: any) => ({
                            value: p.id,
                            label: p.label,
                          }))}
                          onChange={(value, option) => {
                            setPriorityFilter(value);
                          }}
                        />
                        <Select
                          mode='multiple'
                          value={assigneeToFilter}
                          title='Assignee'
                          placeholder='Assignee'
                          allowClear
                          style={{ width: selectWidth }}
                          options={assignees}
                          optionRender={(user) => (
                            <span style={{ display: 'flex', gap: '.3rem' }}>
                              {user.value === null && (
                                <Avatar
                                  size={25}
                                  style={{
                                    backgroundColor: 'orange',
                                  }}
                                  icon={<CloseCircleOutlined />}
                                />
                              )}
                              {user.value !== null && (
                                <UserAvatar
                                  token={user.data.avatar}
                                  size={25}
                                />
                              )}
                              {user.label}
                            </span>
                          )}
                          onChange={(value, option) => {
                            setAssigneeToFilter(value);
                          }}
                        />

                        {/* <div> */}
                        {/* Quests   */}
                        <Select
                          mode='multiple'
                          value={questsToFilter}
                          title='Quests'
                          placeholder='Quests'
                          allowClear
                          style={{ width: selectWidth }}
                          options={quests?.map((quest: any) => ({
                            value: quest.id,
                            label: quest.label,
                          }))}
                          onChange={(value, option) => {
                            setQuestsToFilter(value);
                          }}
                        />
                        {/* </div> */}

                        <div>
                          <Select
                            allowClear
                            style={{ width: selectWidth }}
                            placeholder='Due Until'
                            value={
                              customDate
                                ? dayjs(customDate).format('DD MMM YYYY')
                                : dueDateValue
                            }
                            onChange={(value) => {
                              switch (value) {
                                case 'overdue':
                                  setDueDateFilter({
                                    lte: dayjs().toISOString(),
                                  });
                                  break;
                                case 'today':
                                  setDueDateFilter({
                                    gte: dayjs()
                                      .startOf('day')
                                      .format('YYYY-MM-DD HH:mm:ss'),
                                    lte: dayjs()
                                      .endOf('day')
                                      .format('YYYY-MM-DD HH:mm:ss'),
                                  });
                                  break;
                                case 'tomorrow':
                                  setDueDateFilter({
                                    gte: dayjs()
                                      .add(1, 'day')
                                      .startOf('day')
                                      .format('YYYY-MM-DD HH:mm:ss'),
                                    lte: dayjs()
                                      .add(1, 'day')
                                      .endOf('day')
                                      .format('YYYY-MM-DD HH:mm:ss'),
                                  });
                                  break;
                                case 'this_week':
                                  setDueDateFilter({
                                    gte: dayjs()
                                      .startOf('isoWeek')
                                      .startOf('day')
                                      .format('YYYY-MM-DD HH:mm:ss'),
                                    lte: dayjs()
                                      .endOf('isoWeek')
                                      .endOf('day')
                                      .format('YYYY-MM-DD HH:mm:ss'),
                                  });
                                  break;
                                case 'next_week':
                                  setDueDateFilter({
                                    gte: dayjs()
                                      .add(1, 'week')
                                      .startOf('isoWeek')
                                      .startOf('day')
                                      .format('YYYY-MM-DD HH:mm:ss'),
                                    lte: dayjs()
                                      .add(1, 'week')
                                      .endOf('isoWeek')
                                      .endOf('day')
                                      .format('YYYY-MM-DD HH:mm:ss'),
                                  });
                                  break;
                                case '14_days':
                                  setDueDateFilter({
                                    gte: dayjs()
                                      .startOf('day')
                                      .format('YYYY-MM-DD HH:mm:ss'),
                                    lte: dayjs()
                                      .add(2, 'week')
                                      .endOf('day')
                                      .format('YYYY-MM-DD HH:mm:ss'),
                                  });
                                  break;
                                case 'custom':
                                  if (!value) {
                                    setDueDateFilter(null);
                                    setDueDateValue(null);
                                    setCustomDate(null);
                                  }
                                  break;
                                default:
                                  setDueDateFilter(null);
                                  break;
                              }
                              setDueDateValue(value);
                              setCustomDate(null);
                            }}
                            dropdownRender={(menu) => {
                              return (
                                <div>
                                  {menu}
                                  <Divider style={{ margin: '4px 0' }} />
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexWrap: 'nowrap',
                                      padding: 8,
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <DatePicker
                                      style={{ width: '100%' }}
                                      variant={'borderless'}
                                      format={'DD MMM YYYY'}
                                      value={customDate}
                                      onClick={(e) => e.stopPropagation()}
                                      onChange={(value) => {
                                        if (!value) {
                                          setDueDateFilter(null);
                                          setDueDateValue(null);
                                          setCustomDate(null);
                                          return;
                                        }
                                        setDueDateFilter({
                                          lte: dayjs(value)
                                            .endOf('day')
                                            .format('YYYY-MM-DD HH:mm:ss'),
                                        });
                                        setDueDateValue('custom');
                                        setCustomDate(value);
                                      }}
                                      showNow={false}
                                    />
                                  </div>
                                </div>
                              );
                            }}
                          >
                            <Select.Option value='overdue'>
                              Overdue
                            </Select.Option>
                            <Select.Option value='today'>Today</Select.Option>
                            <Select.Option value='tomorrow'>
                              Tomorrow
                            </Select.Option>
                            <Select.Option value='this_week'>
                              This Week
                            </Select.Option>
                            <Select.Option value='next_week'>
                              Next Week
                            </Select.Option>
                            <Select.Option value='14_days'>
                              Next 14 days
                            </Select.Option>
                          </Select>
                        </div>

                        <Select
                          value={taskState}
                          defaultValue={'Active'}
                          title='Tasks'
                          placeholder='Tasks'
                          style={{ width: selectWidth }}
                          options={[
                            {
                              value: 'all',
                              label: 'All',
                            },
                            {
                              value: 'active',
                              label: 'Active',
                            },
                            {
                              value: 'completed',
                              label: 'Completed',
                            },
                            {
                              value: 'archived',
                              label: 'Archived',
                            },
                          ].map((board: any) => ({
                            value: board.value,
                            label: board.label,
                          }))}
                          onChange={(value: any, option) => {
                            setTaskState(value);
                          }}
                        />
                      </div>
                    </>
                  ),
                },
              ]}
            />
            {selectedRowKeys.length !== 0 && (
              <>
                <br />
                <BulkUpdateTask
                  boardKey={boardKey}
                  projectKey={project.key}
                  taskIds={selectedRowKeys}
                  selectedTasksCount={selectedRowKeys.length}
                  onCompleted={() => {
                    refetchOverview();
                    setSelectedRowKeys([]);
                  }}
                />
              </>
            )}
          </>
        )}
        tableLayout='fixed'
        dataSource={overviewData?.tasks}
        columns={columns}
        scroll={{ y: '70vh' }}
        style={{ minHeight: '70vh' }}
        rowSelection={rowSelection}
        rowKey={(record) => record.id}
        loading={overviewIsLoading || overviewIsFetching}
        rowHoverable={false}
        pagination={{
          total: overviewData?.totalTasks,
          showTotal(total, range) {
            return `${range[0]}-${range[1]} of ${total} items`;
          },
          pageSize: limit,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '50'],
          onChange: (page, pageSize) => {
            setSkip((page - 1) * pageSize);
            setLimit(pageSize);
          },
        }}
      />

      {
        activeTask && modalOpen && (
          <TaskDetailsModal2
            taskKey={activeTask.taskKey}
            setShowTaskDetailsModal={setModalOpen}
            showTaskDetailsModal={modalOpen}
            onUpdateTask={() => {
              // ma nista
            }}
            onCancel={() => {
              setModalOpen(false);
              setActiveTask(null);
              navigate(`/project/${project.key}/overview`);
            }}
          />
        )
      }
    </div>
  );
}

export default ProjectOverview_List;
