import { useEffect, useState } from 'react';
import { Card, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { api } from '../../api';
import { Button, ConfigProvider, Input, Space, theme } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';

export default function Login() {
  // const userStore = useUserStore();
  const navigate = useNavigate();
  const [subdomain, setSubdomain] = useState('');

  const [loginInProgress, setLoginInProgress] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoginInProgress(true);
    const data = new FormData(event.currentTarget);

    const email = data.get('email') as string;
    const password = data.get('password') as string;

    try {
      const token = await api.post(
        '/api/login',
        { email, password },
        { withCredentials: true }
      );

      localStorage.setItem('token', token.data);
      return navigate('/');
    } catch (error: any) {
      messageApi.error(error.response.data.message);
      console.log(error, error.response);
    }
    setLoginInProgress(false);
  };

  const savedTheme = localStorage.getItem('theme');

  let themeToSelect = theme.defaultAlgorithm;

  switch (savedTheme) {
    case 'dark': {
      themeToSelect = theme.darkAlgorithm;
      break;
    }
    default: {
      themeToSelect = theme.defaultAlgorithm;
    }
  }

  useEffect(() => {
    const subdomain = window.location.host.split('.')[0];
    setSubdomain(subdomain);

  }, [])

  // const isLogedIn = async () => {

  //   try {
  //     // const token = await api.get(
  //     //   '/api/me',
  //     //   { withCredentials: true }
  //     // );

  //     // return navigate('/');

  //   } catch (error: any) {
  //     localStorage.removeItem('token')
  //     console.log(error, error.response);
  //   }

  // }

  // useEffect(() => {

  //   isLogedIn()


  // }, []);

  return (
    <ConfigProvider
      theme={{
        algorithm: [theme.darkAlgorithm],
      }}
    >
      {contextHolder}
      <Space
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Title level={1}>{subdomain}</Title>
        <Card
          style={{ padding: "1rem" }}>
          <div style={{ textAlign: "center" }}>
            <Title level={3}>Log in</Title>
          </div>
          <form onSubmit={handleSubmit}>
            <Input name='email' required placeholder='Email' />
            <div style={{ marginTop: '1rem' }}></div>

            <Input.Password
              name='password'
              required
              placeholder='Password'
              type='password'
            />

            <div style={{ marginTop: '1rem' }}></div>

            <div style={{ textAlign: 'center' }}>
              <Button
                loading={loginInProgress}
                htmlType='submit'
                type='primary'
                disabled={loginInProgress}
              >
                Log In
              </Button>

              {/* <div>
              <Text>Forgot password?</Text>
              <Button disabled={loginInProgress} href='#' type='link'>
                Reset
              </Button>
            </div> */}
            </div>
          </form>
        </Card>
      </Space>
    </ConfigProvider >
  );
}
