import { useEffect, useState } from 'react';
import { api } from '../../api';
import { useProjectStore } from '../../state/projectState';
import { Button, Divider, Select, Skeleton, Space, Tooltip } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import CreateProjectModal from '../../modals/CreateProjectModal/createProjectModal';
import UpdateProjectModal from '../../modals/UpdateProjectModal/updateProjectModal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  DownCircleOutlined,
  FileUnknownOutlined,
  GlobalOutlined,
  PieChartOutlined,
  PlusOutlined,
  ReadOutlined,
  SettingOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import { useUserStore } from '@/state/userState';
import { CustomIcon } from '../Icon';
import useIsMobile from '@/hooks/useMobile';

export default function ProjectSelector() {
  const projectStore = useProjectStore();
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const [showUpdateProjectModal, setShowUpdateProjectModal] = useState(false);
  const { projectKey } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [hasProjects, setHasProjects] = useState(true);

  const userStore = useUserStore();

  const fetchProjects = async () => {
    const { data } = await api.get('/api/projects');

    const favoriteProjects = data
      .filter((project: any) => project.isFavorite)
      .map((project: any) => ({
        label: project.label,
        value: project.id,
        key: project.key,
        isFavorite: project.isFavorite,
        id: project.id,
      }));

    const restOfProjects = data
      .filter((project: any) => !project.isFavorite)
      .map((project: any) => ({
        label: project.label,
        value: project.id,
        key: project.key,
        isFavorite: project.isFavorite,
        id: project.id,
      }));

    const optionGroups = [
      {
        label: 'Starred Projects',
        options: favoriteProjects,
      },
      {
        label: 'All Projects',
        options: restOfProjects,
      },
    ].filter((group) => group.options.length > 0);

    return { allProjects: data, optionGroups };
  };

  const { data, isLoading, refetch } = useQuery<any>({
    queryKey: ['projects'],
    queryFn: fetchProjects,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  useEffect(() => {
    if (
      data?.allProjects &&
      data?.allProjects.length &&
      !pathname.includes('/me/') &&
      !pathname.includes('/t/')
    ) {
      if (projectKey) {
        const project = data?.allProjects.find(
          (p: any) => p.key === projectKey
        );
        if (project) {
          projectStore.setProject(project);
        }
      } else {
        if (localStorage.getItem('lastSelectedProject')) {
          const lastSelectedProject = JSON.parse(
            localStorage.getItem('lastSelectedProject') as string
          );
          projectStore.setProject(lastSelectedProject);
          navigate(`/project/${lastSelectedProject.key}/overview`);
        } else {
          projectStore.setProject({
            key: data?.allProjects[0].key,
            label: data?.allProjects[0].label,
            id: data?.allProjects[0].id,
          });
          navigate(`/project/${data?.allProjects[0].key}/overview`);
        }
      }
    }

    if (!isLoading && data?.allProjects.length === 0) {
      setHasProjects(false);
      projectStore.setHasProject(false);
    }
  }, [data, projectKey]);

  const isMobile = useIsMobile();

  if (isLoading) return <Skeleton active />;


  const size = isMobile ? 'small' : 'middle';

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Text>Project</Text>
        </div>
        <div>
          {(userStore.user?.isAdmin || userStore.user?.isManager) && (
            <Button
              type='link'
              size={size}
              onClick={() => {
                setShowCreateProjectModal(true);
              }}
            >
              <PlusOutlined />
            </Button>
          )}
        </div>
      </div>
      <Divider style={{ margin: '.5rem 0' }} />
      {hasProjects && (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 10,
              alignItems: 'center',
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <Select
                loading={isLoading}
                showSearch
                size={size}
                style={{ width: '100%' }}
                title='Projects'
                value={projectStore?.project?.label}
                defaultValue={projectStore?.project?.label}
                onSelect={(value, option: any) => {
                  projectStore.setProject(option);
                  localStorage.setItem(
                    'lastSelectedProject',
                    JSON.stringify(option)
                  );
                  navigate(`/project/${option.key}/overview`);
                }}
                filterOption={(input, option) =>
                  option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                optionRender={(project) => (
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      <Text>{project.label}</Text>
                    </div>
                    <div>
                      <Button
                        type='link'
                        size={size}
                        onClick={async (e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          await api.post(
                            `/api/user-toggle-favorite-project/${project.value}`
                          );
                          await refetch();
                        }}
                      >
                        {project?.data?.isFavorite ? (
                          <StarFilled />
                        ) : (
                          <StarOutlined />
                        )}
                      </Button>
                    </div>
                  </div>
                )}
                options={data?.optionGroups}
              />
              {/* {data.map((project: any) => (
                <Button
                  key={project.key}
                  onClick={() => {
                    projectStore.setProject(project);
                    localStorage.setItem(
                      'lastSelectedProject',
                      JSON.stringify(project)
                    );
                    navigate(`/project/${project.key}/overview`);
                  }}
                  type={project.key === projectStore.project?.key ? 'primary' : 'text'}
                  style={{ width: '100%', justifyContent: 'left' }}
                >
                  {project.label}
                </Button>
              ))} */}
            </div>
            {/* <div> TODO: Add favorite button
              <Button variant='solid' shape='circle' icon={<StarOutlined />} title='Mark as favorite' size='small' />
            </div> */}
          </div>
          <Divider style={{ margin: '.5rem 0' }} />
          {/* <div style={{ margin: '.5rem 0' }}> */}
          <Space direction='vertical' style={{ width: '100%' }}>
            <div>
              <Button
                onClick={() => {
                  navigate(`/project/${projectKey}/overview`);
                }}
                size={size}
                style={{ width: '100%', justifyContent: 'left' }}
                type={pathname.includes('overview') ? 'primary' : 'text'}
              >
                <GlobalOutlined />
                Overview
              </Button>
            </div>

            <div>
              <Button
                size={size}
                onClick={() => {
                  navigate(`/project/${projectKey}/quests`);
                }}
                style={{ width: '100%', justifyContent: 'left' }}
                type={pathname.includes('quests') ? 'primary' : 'text'}
              >
                <DownCircleOutlined />
                Quests
              </Button>
            </div>

            <div>
              <Button
                onClick={() => {
                  navigate(`/project/${projectKey}/pages`);
                }}
                style={{ width: '100%', justifyContent: 'left' }}
                type={pathname.includes('pages') ? 'primary' : 'text'}
                size={size}
              >
                <ReadOutlined />
                Pages
              </Button>
            </div>

            <div>
              {(userStore.user?.isAdmin || userStore.user?.isManager) && (
                <Button
                  size={size}
                  onClick={() => {
                    setShowUpdateProjectModal(true);
                  }}
                  style={{ width: '100%', justifyContent: 'left' }}
                  type='text'
                >
                  <SettingOutlined />
                  Settings
                </Button>
              )}
            </div>
          </Space>
          {/* </div> */}
        </div>
      )}
      {showCreateProjectModal && (
        <CreateProjectModal
          open={showCreateProjectModal}
          handleOk={() => {
            setShowCreateProjectModal(false);
            if (!projectStore.hasProjects) {
              projectStore.setHasProject(true);
              setHasProjects(true);
            }
            refetch();
          }}
          handleCancel={() => {
            setShowCreateProjectModal(false);
          }}
        />
      )}
      {showUpdateProjectModal && (
        <UpdateProjectModal
          open={showUpdateProjectModal}
          handleOk={() => {
            setShowUpdateProjectModal(false);
            refetch();
          }}
          handleCancel={() => {
            setShowUpdateProjectModal(false);
          }}
        />
      )}
    </div>
  );
}
