import { api } from '@/api';
import { RelativeTime } from '@/components/RelativeTime';
import SlackChannelsDropdown from '@/components/SlackChannelsDropdown';
import { useQuery } from '@tanstack/react-query';
import { Table } from 'antd';
import dayjs from 'dayjs';

export default function AdminProjects() {
  async function getProjects() {
    const response = await api.get('/api/admin/projects');
    return response.data;
  }

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['projects', 'admin'],
    queryFn: getProjects,
  });


  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Public',
      dataIndex: 'isPublic',
      key: 'isPublic',
      render: (value: boolean) => (value ? 'Yes' : 'No'),
    },
    {
      title: 'Boards',
      dataIndex: 'boardCount',
      key: 'boardCount',
    },
    {
      title: 'Tasks',
      dataIndex: 'taskCount',
      key: 'taskCount',
    },
    {
      title: 'Slack Channel',
      dataIndex: 'slackChannelId',
      key: 'slackChannelId',
      render: (value: string, row: any) => (
        <SlackChannelsDropdown
          key={row.id}
          defaultChannel={value}
          onSelectChange={async (channelId) => {
            await api.put(`/api/projects/${row.key}`, {
              slackChannelId: channelId,
              key: null,
              userIds: null,
            });
            await refetch();
          }}
          hideLabel
        />
      ),
    },
    {
      title: 'Owner',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value: string) => <RelativeTime date={value} showHoursAndMinutes />,
    },
    {
      title: 'Deleted',
      dataIndex: 'deletedAt',
      key: 'deletedAt',
      render: (value: string) =>
        value ? dayjs(value).format('DD MMM YYYY, HH:mm') : '',
    },
  ];

  return (
    <div>
      <h1>Projects</h1>
      <Table
        size='small'
        loading={isLoading}
        dataSource={data}
        columns={columns}
        bordered
      />
    </div>
  );
}
