import { Skeleton, Tabs, TabsProps } from 'antd';
import ProjectOverview_List from './List';
import Activity from './Activity';
import Summary from './Summary';
import { CustomIcon } from '@/components/Icon';
import { useProjectStore } from '@/state/projectState';

export default function ProjectOverview() {

  const { project } = useProjectStore();

  if (!project) {
    return <Skeleton />;
  }

  const tabs: TabsProps['items'] = [
    // {
    //   label: 'Summary',
    //   children: <Summary />,
    //   key: '1',
    // },
    {
      label: 'Task List',
      children: <ProjectOverview_List />,
      key: '2',
    },
    {
      label: 'Activity',
      children: <Activity />,
      key: '3',
    },
  ];

  return <Tabs defaultActiveKey='1' items={tabs} type="card" />;
}
