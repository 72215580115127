import {
  AlertOutlined,
  BugOutlined,
  CheckSquareOutlined,
  NodeIndexOutlined,
} from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import Text from 'antd/es/typography/Text';

export const getTaskTypeIcon = (type: 'bug' | 'task' | 'story') => {
  switch (type) {
    case 'bug':
      return (
        <Tooltip title='Bug'>
          <Tag color='red' bordered={false}>
            <BugOutlined />
          </Tag>
        </Tooltip>
      );

    case 'task':
      return (
        <Tooltip title='Task'>
          <Tag bordered={false}>
            <CheckSquareOutlined />
          </Tag>
        </Tooltip>
      );

    case 'story':
      return (
        <Tooltip title='Story'>
          <Tag bordered={false} color='blue'>
            <NodeIndexOutlined />
          </Tag>
        </Tooltip>
      );
  }
};

export const getTaskPriorityIcon = (
  priority: 'low' | 'lowest' | 'normal' | 'high' | 'highest'
) => {
  switch (priority) {
    case 'lowest':
      return (
        <Tooltip title='Lowest Priority'>
          <Tag bordered={false} color='purple'>
            <AlertOutlined />
          </Tag>
        </Tooltip>
      );
    case 'low':
      return (
        <Tooltip title='Low Priority'>
          <Tag bordered={false} color='blue'>
            <AlertOutlined />
          </Tag>
        </Tooltip>
      );

    case 'normal':
      return (
        <Tooltip title='Normal Priority'>
          <Tag bordered={false}>
            <AlertOutlined />
          </Tag>
        </Tooltip>
      );

    case 'high':
      return (
        <Tooltip title='High Priority'>
          <Tag color='orange' bordered={false}>
            <AlertOutlined />
          </Tag>
        </Tooltip>
      );

    case 'highest':
      return (
        <Tooltip title='Highest Priority'>
          <Tag color='red' bordered={false}>
            <AlertOutlined />
          </Tag>
        </Tooltip>
      );
  }
};


export const base64ToBlob = (base64String: string, contentType = '') => {
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }

  const byteArray = new Uint8Array(byteArrays);
  return new Blob([byteArray], { type: contentType });
}
