import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { LexoRank } from 'lexorank';
import { api } from '../../../api';
import { useBoardStore } from '../../../state/boardState';
import {
  Button,
  Input,
  Modal,
  Select,
  Skeleton,
  message,
  notification,
} from 'antd';
import Text from 'antd/es/typography/Text';

import { BoardHeader } from './components/BoardHeader';
import { Column } from './components/Column';
import { useUserStore } from '@/state/userState';
import { useOptionStore } from '@/state/options';
import { sseClient } from '@/api/realtime';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

function Board() {
  const [showNewColumnForm, setShowNewColumnForm] = useState(false);
  const [newColumnLabel, setNewColumnLabel] = useState('');
  const [newColumnCategoryId, setNewColumnCategoryId] = useState('j82senyrwaaza0m8omtn6jgu');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [realTime, setRealTime] = useState(true);
  const [assigneeFilter, setAssigneeFilter] = useState<any>([]);
  const [priorityFilter, setPriorityFilter] = useState<any>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [notificationApi, notificationContextHolder] = notification.useNotification();
  const [runEventHandler, setRunEventHandler] = useState(true);

  const { projectKey, boardKey } = useParams();
  const boardStore = useBoardStore();
  const { user } = useUserStore();
  const optionStore = useOptionStore();
  const queryClient = useQueryClient();

  async function getPriorities() {
    const response = await api.get(`/api/priority`);
    return response.data;
  }

  const { data: priorities } = useQuery({
    queryKey: ['priority'],
    queryFn: getPriorities,
    staleTime: Infinity,
  });

  async function getColumns() {
    const response = await api.get(`/api/projects/${projectKey}/boards/${boardKey}/columns`);
    setCurrentColumns(response.data);
    return response.data;
  }

  const {
    data,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['columns', projectKey, boardKey],
    queryFn: getColumns,
  });

  async function getProjectUsers() {
    const response = await api.get(`/api/projects/${projectKey}/users`);
    return response.data;
  }

  const { data: users, isLoading: usersIsLoading } = useQuery({
    queryKey: [projectKey, 'users'],
    queryFn: getProjectUsers,
  });

  const [currentColumns, setCurrentColumns] = useState<any>([]);


  const handleTaskUpdated = (event: any) => {
    if (!runEventHandler) {
      setRunEventHandler(true);
      return;
    }
    const newEvent = JSON.parse(JSON.parse(event.data));
    const task = newEvent.payload;

    const oldColumn = currentColumns.find((col: any) => col.id === task.oldColumnId);
    const newColumn = currentColumns.find((col: any) => col.id === task.columnId);

    if (!oldColumn && !newColumn) return;

    if (oldColumn.id === newColumn?.id) {
      const newItems = oldColumn.items.map((item: any) => {
        return item.id === task.id ? task : item;
      });

      setCurrentColumns((cols: any) =>
        cols.map((col: any) =>
          col.id === oldColumn.id ? { ...oldColumn, items: newItems } : col
        )
      );
    } else {
      const oldItems = oldColumn.items.filter((item: any) => item.id !== task.id);
      const newItems = newColumn ? newColumn?.items.concat(task) : newColumn?.items;

      setCurrentColumns((cols: any) =>
        cols.map((col: any) =>
          col.id === oldColumn.id
            ? { ...oldColumn, items: oldItems }
            : col.id === newColumn?.id
              ? { ...newColumn, items: newItems }
              : col
        )
      );
    }
  };

  const handleTaskCreated = (event: any) => {
    const newEvent = JSON.parse(JSON.parse(event.data));
    const task = newEvent.payload;
    const newColumn = currentColumns.find((col: any) => col.id === task.columnId);

    if (!newColumn) return;

    const newItems = newColumn.items.concat(task);
    setCurrentColumns((cols: any) =>
      cols.map((col: any) =>
        col.id === newColumn.id ? { ...newColumn, items: newItems } : col
      )
    );
  };

  const handleTaskDeleted = (event: any) => {
    const newEvent = JSON.parse(JSON.parse(event.data));
    const task = newEvent.payload;
    const column = currentColumns.find((col: any) => col.id === task.columnId);

    if (!column) return;

    const newItems = column.items.filter((item: any) => item.id !== task.id);
    setCurrentColumns((cols: any) =>
      cols.map((col: any) =>
        col.id === column.id ? { ...column, items: newItems } : col
      )
    );
  };

  // If you want SSE-based updates, uncomment and adjust these:
  useEffect(() => {
    if (!currentColumns || currentColumns.length === 0) return;
    sseClient.addEventListener('board_task_updated', handleTaskUpdated);
    sseClient.addEventListener('task_created', handleTaskCreated);
    sseClient.addEventListener('task_deleted', handleTaskDeleted);

    return () => {
      sseClient.removeEventListener('board_task_updated', handleTaskUpdated);
      sseClient.removeEventListener('task_created', handleTaskCreated);
      sseClient.removeEventListener('task_deleted', handleTaskDeleted);
    };
  }, [currentColumns, runEventHandler]);



  const onDragEnd = (result: any) => {
    const { source, destination, type, draggableId } = result;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    setRunEventHandler(false);

    if (type === 'column') {
      const newColumnOrder = Array.from(currentColumns);
      const [movedColumn] = newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, movedColumn);

      const oldColumns = [...currentColumns];
      setCurrentColumns(newColumnOrder);

      try {
        api.put(`/api/columns/${draggableId}/reorder`, {
          position: destination.index + 1,
        });
      } catch (error) {
        setCurrentColumns(oldColumns);
      }

      return;
    }

    const startColumn = currentColumns.find(
      (col: any) => col.id === source.droppableId
    );
    const finishColumn = currentColumns.find(
      (col: any) => col.id === destination.droppableId
    );

    if (!startColumn || !finishColumn) return;

    const oldColumns = [...currentColumns];

    if (startColumn === finishColumn) {
      // Moving task within the same column
      const newItems = Array.from(startColumn.items);
      let [movedItem] = newItems.splice(source.index, 1) as any;
      newItems.splice(destination.index, 0, movedItem);

      setCurrentColumns((cols: any) =>
        cols.map((col: any) =>
          col.id === startColumn.id ? { ...startColumn, items: newItems } : col
        )
      );

      const afterItem: any = newItems[destination.index - 1];
      const beforeItem: any = newItems[destination.index + 1];

      let dataToSend: any = {
        newColumnId: destination.droppableId,
      };

      try {
        if (afterItem && beforeItem) {
          const beforeRank = LexoRank.parse(beforeItem.orderKey);
          const afterRank = LexoRank.parse(afterItem.orderKey);
          const newRank = beforeRank.between(afterRank).toString();
          movedItem.orderKey = newRank

          dataToSend.afterId = afterItem?.id;
          dataToSend.beforeId = beforeItem?.id;
        }

        if (afterItem && !beforeItem) {
          dataToSend.afterId = afterItem?.id;
          movedItem.orderKey = LexoRank.parse(afterItem.orderKey).genNext().toString();
        }
        if (beforeItem && !afterItem) {
          dataToSend.beforeId = beforeItem?.id;
          movedItem.orderKey = LexoRank.parse(beforeItem.orderKey).genPrev().toString();
        }


      } catch (error) {
        setCurrentColumns(oldColumns);
      }

      queryClient.setQueryData(['columns', projectKey, boardKey],
        (oldData: any) => {
          const newColumns = oldData.map((col: any) => {
            if (col.id === startColumn.id) {
              return { ...startColumn, items: newItems };
            }
            return col;
          });
          return newColumns;
        }
      );

      try {
        api.post(`/api/tasks/${draggableId}/move`, dataToSend);
      } catch (error) {
        setCurrentColumns(oldColumns);
      }
    } else {
      // Moving task to a different column
      const startItems = Array.from(startColumn.items);
      const finishItems = Array.from(finishColumn.items);
      const [movedItem] = startItems.splice(source.index, 1) as any;
      finishItems.splice(destination.index, 0, movedItem);

      setCurrentColumns((cols: any) =>
        cols.map((col: any) =>
          col.id === startColumn.id
            ? { ...startColumn, items: startItems }
            : col.id === finishColumn.id
              ? { ...finishColumn, items: finishItems }
              : col
        )
      );

      const dataToSend: any = {
        newColumnId: destination.droppableId,
      }

      const afterItem: any = finishItems[destination.index - 1];
      const beforeItem: any = finishItems[destination.index + 1];

      try {
        if (afterItem && beforeItem) {
          const beforeRank = LexoRank.parse(beforeItem.orderKey);
          const afterRank = LexoRank.parse(afterItem.orderKey);
          const newRank = beforeRank.between(afterRank).toString();
          movedItem.orderKey = newRank

          dataToSend.afterId = afterItem?.id;
          dataToSend.beforeId = beforeItem?.id;
        }

        if (afterItem && !beforeItem) {
          dataToSend.afterId = afterItem?.id;
          movedItem.orderKey = LexoRank.parse(afterItem.orderKey).genNext().toString();
        }
        if (beforeItem && !afterItem) {
          dataToSend.beforeId = beforeItem?.id;
          movedItem.orderKey = LexoRank.parse(beforeItem.orderKey).genPrev().toString();
        }

      } catch (error) {
        setCurrentColumns(oldColumns);
      }



      queryClient.setQueryData(['columns', projectKey, boardKey],
        (oldData: any) => {
          const newColumns = oldData.map((col: any) => {
            if (col.id === startColumn.id) {
              return { ...startColumn, items: startItems };
            }
            if (col.id === finishColumn.id) {
              return { ...finishColumn, items: finishItems };
            }
            return col;
          });
          return newColumns;
        }
      );

      try {
        api.post(`/api/tasks/${draggableId}/move`, dataToSend);
      } catch (error) {
        setCurrentColumns(oldColumns);
      }
    }
  };

  if (isLoading || !currentColumns) {
    return (
      <div>
        <div style={{ display: 'flex', gap: '6rem' }}>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
        <div style={{ display: 'flex', gap: '6rem' }}>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
        <div style={{ display: 'flex', gap: '6rem' }}>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      </div>
    );
  }

  return (
    <div>
      {contextHolder}
      {notificationContextHolder}

      <BoardHeader
        assigneeFilter={assigneeFilter}
        setAssigneeFilter={setAssigneeFilter}
        filterType={filterType}
        setFilterType={setFilterType}
        realTime={realTime}
        setRealTime={setRealTime}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        users={users}
        usersIsLoading={usersIsLoading}
        refetch={refetch}
        currentPriorities={priorities}
        priorityFilter={priorityFilter}
        setPriorityFilter={setPriorityFilter}
        currentColumns={currentColumns}
        isFetching={isFetching}
        key={boardKey}
      />

      <div
        className='kanban'
        style={{
          marginTop: '1rem',
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId='all-columns'
            direction='horizontal'
            type='column'
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ display: 'flex', gap: '.5rem' }}
              >
                {currentColumns.map((column: any, index: number) => (
                  <Draggable
                    key={column.id}
                    draggableId={column.id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        style={{ ...provided.draggableProps.style }}
                      >
                        <Column
                          key={column.id}
                          column={column}
                          assigneeFilter={assigneeFilter}
                          priorityFilter={priorityFilter}
                          filterType={filterType}
                          searchTerm={searchTerm}
                          refetch={refetch}
                          realTime={realTime}
                          width={`${80 / currentColumns.length}vw`}
                          handle={provided.dragHandleProps}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div>
          {(user?.isAdmin || user?.isManager) && (
            <Button
              onClick={() => {
                setShowNewColumnForm(true);
              }}
              type='link'
            >
              + Add Column{' '}
            </Button>
          )}
        </div>
      </div>
      {showNewColumnForm && (
        <Modal
          style={{ top: 20 }}
          open={showNewColumnForm}
          title='Create New Column'
          onCancel={() => {
            setShowNewColumnForm(false);
          }}
          onOk={async () => {
            try {
              await api.post(`/api/boards/${boardStore.board?.id}/columns`, {
                label: newColumnLabel,
                categoryId: newColumnCategoryId,
              });
              await refetch();
              setNewColumnLabel('');
              setShowNewColumnForm(false);
            } catch (error) {
              console.log(error);
              notificationApi.error({
                message: 'Error',
                description: 'Failed to create column',
              });
            }
          }}
          okText='Create'
        >
          <div>
            <Text>Label</Text>
            <Input
              autoFocus
              value={newColumnLabel}
              onChange={(e) => setNewColumnLabel(e.target.value)}
            />
            <Text>Type</Text>
            <Select
              style={{ width: '100%' }}
              defaultValue={'j82senyrwaaza0m8omtn6jgu'}
              options={[
                {
                  label: 'Todo',
                  value: 'j82senyrwaaza0m8omtn6jgu',
                },
                {
                  label: 'In Progress',
                  value: 'gt2i39u4ierremke4g926x0x',
                },
              ]}
              placeholder='Select a category'
              onChange={(value) => {
                setNewColumnCategoryId(value);
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Board;
