import { api } from '@/api';
import useIsMobile from '@/hooks/useMobile';
import {
  AutoComplete,
  Button,
  Divider,
  List,
  Modal,
  Skeleton,
  Tag,
} from 'antd';
import Title from 'antd/es/typography/Title';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function SearchModal({
  searchModalOpen,
  setSearchModalOpen,
  setShowCreateTaskGlobal,
}: {
  searchModalOpen: boolean;
  setSearchModalOpen: (value: boolean) => void;
  setShowCreateTaskGlobal: (value: boolean) => void;
}) {
  const [searchLoading, setSearchLoading] = useState(false);

  const [searchText, setSearchText] = useState('');

  const [recentTasks, setRecentTasks] = useState([]);

  const inputRef: any = useRef(null);

  const navigate = useNavigate();

  const getRecentTask = async () => {
    const recentTasks = await api.get('/api/recent-tasks');
    setRecentTasks(recentTasks.data);
  };

  useEffect(() => {
    setSearchText('');
    if (searchModalOpen) {
      getRecentTask();
    }
  }, [searchModalOpen]);

  const fetchSuggestions = async (value: string) => {
    setSearchLoading(true);
    if (value) {
      await api.get(`/api/tasks/${value}/search`).then((res) => {
        setRecentTasks(res.data.tasks);
      });
    } else {
      getRecentTask();
    }
    setSearchLoading(false);
  };

  // Debounced function for fetching suggestions
  const debouncedFetchSuggestions = useMemo(
    () => debounce(fetchSuggestions, 700),
    []
  );

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (value[value.length - 1] === '-') {
      return;
    }
    debouncedFetchSuggestions(value);
  };

  const renderGroupedTasks = () => {
    return recentTasks.map((task: any) => {
      return (
        <List.Item
          key={task.id}
          onClick={() => {
            navigate(`?openTask=${task.taskKey}`);
          }}
          style={{ cursor: 'pointer' }}
        >
          <List.Item.Meta
            title={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <Tag>{task.taskKey}</Tag>
                  <span>{task.taskLabel}</span>
                </div>
                <div>
                  <Tag>{task.columnLabel}</Tag>
                </div>
              </div>
            }
          />
        </List.Item>
      );
    });
  };

  const isMobile = useIsMobile();

  return (
    <Modal
      open={searchModalOpen}
      style={{ top: isMobile ? 5 : 20 }}
      destroyOnClose
      onCancel={() => {
        setSearchModalOpen(false);
        setSearchText('');
      }}
      width={
        isMobile ? '100vw' : '50vw'
      }
      footer={null}
      afterOpenChange={(open) => {
        if (open) {
          inputRef?.current?.focus();
        }
      }}
      afterClose={() => {
        setSearchText('');
      }}
    >
      <Title level={4}>Search for tasks</Title>
      <AutoComplete
        style={{ width: '100%' }}
        value={searchText}
        allowClear
        ref={inputRef}
        variant='filled'
        placeholder='Search for tasks'
        onSearch={handleSearch}
        options={[]}
      />

      <Title level={4}>{searchText !== '' ? 'Search Results' : 'Recently visited'}</Title>
      <hr />

      {searchLoading ? (
        <div>
          <Skeleton active />
          <Skeleton active />
        </div>
      ) : (
        <List style={{ height: '50vh', overflow: 'auto' }}>
          {!searchLoading && recentTasks.length === 0 && (
            <div>
              <Title level={5}>No tasks found</Title>
              <Button
                onClick={() => {
                  setShowCreateTaskGlobal(true);
                }}
              >
                {' '}
                + Create new task
              </Button>
            </div>
          )}

          {renderGroupedTasks()}
        </List>
      )}
    </Modal>
  );
}
