import { api } from '@/api';
import Editor from '@/components/Editor/index3';
import { RelativeTime } from '@/components/RelativeTime';
import { ArrowRightOutlined, SyncOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import {
  Badge,
  Button,
  Card,
  Collapse,
  CollapseProps,
  List,
  Skeleton,
  Tag,
  Timeline,
} from 'antd';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import { Link } from 'react-router-dom';

export const Due = ({ projectId }: { projectId: string }) => {
  const getCreated = async () => {
    const response = await api.get(
      `/api/projects/${projectId}/overview/due`
    );
    return response.data;
  };

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['due', projectId],
    queryFn: getCreated,
  });

  return (
    <Card size='small'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text>
          Due in next 7 days{' '}
          <Tag bordered={false} color='blue'>
            {data?.length}
          </Tag>
        </Text>
        <Button
          onClick={() => {
            refetch();
          }}
          title='Refresh'
          size='small'
        >
          <SyncOutlined />
        </Button>
      </div>
      <div
        style={{
          height: '40vh',
          overflow: 'auto',
          padding: '5px 0',
        }}
      >
        <List
          loading={isFetching || isLoading}
          dataSource={data}
          renderItem={(item: any) => (
            <Card style={{ marginBottom: '1rem' }}>
              <div style={{ marginBottom: '.3rem' }}>
                <Tag bordered={false}>{item.boardName}</Tag>
                <Tag bordered={false}>{item.columnName}</Tag>
              </div>
              <div style={{ marginBottom: '.3rem' }}>
                <Link to={`?openTask=${item.key}`}>
                  {`[${item.key}]`} {item.label}
                </Link>
              </div>
              <div>
                <Text type='secondary'>
                  <RelativeTime date={item.dueDate} />
                </Text>
              </div>
            </Card>
          )}
        />
      </div>
    </Card>
  );
};
