import { api } from '../../../../api';
import {
  Button,
  Card,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Table,
  message,
} from 'antd';
import { useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { QuestionCircleOutlined } from '@ant-design/icons';

export default function UsersTab() {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [messageApi, contextHolder] = message.useMessage();
  const [showPending, setShowPending] = useState(false);
  const [showRoleHelpModal, setShowRoleHelpModal] = useState(false);

  async function getUsers() {
    const response = await api.get(
      `/api/admin/users?showPending=${showPending ? 1 : 0}`
    );
    setUsers(response.data);
  }
  useEffect(() => {
    getUsers();
  }, [showPending]);

  const columns: ColumnsType = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      hidden: showPending,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'roleId',
      key: 'roleId',
      render: (_, row) => {
        return (
          <Select
            defaultValue={row.roleId}
            variant='borderless'
            style={{ width: '100%' }}
            onChange={async (value, option) => {
              if (showPending) {
                const dataToSend = {
                  roleId: value,
                  inviteId: row.userId,
                };
                await api.put('/api/admin/invite/role', dataToSend);
              } else {
                const dataToSend = {
                  roleId: value,
                  userId: row.userId,
                };
                await api.put('/api/admin/user/role', dataToSend);
              }
            }}
          >
            <Select.Option value='kzhvv3neroibsx6ljrm8q4mu'>
              Admin
            </Select.Option>
            <Select.Option value='evbtjcmlieh1ybr66yq4rkaf'>User</Select.Option>
            <Select.Option value='pjudzlth7qyh7x4359vemi5e'>
              Manager
            </Select.Option>
          </Select>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      hidden: !showPending,
      render: (_, row) => {
        return row.isValid ? (
          <Text type='success'>Valid</Text>
        ) : (
          <Text type='warning'>Expired</Text>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      hidden: showPending,
      render: (_, row) => (
        <Popconfirm
          title={
            row.active
              ? 'Are you sure? user will be removed from all projects, and login access will be revoked'
              : 'Reactivate user? user will have to be added to projects again, and login access will be restored'
          }
          okButtonProps={{ type: 'primary', danger: true }}
          okText='Confirm'
          onConfirm={async () => {
            try {
              await api.put('/api/admin/user/access', {
                userId: row.userId,
                active: !row.active,
              });
              await getUsers();
            } catch (error: any) {
              messageApi.error(
                error?.response?.data?.message ?? 'Error has occurred'
              );
            }
          }}
        >
          {row.active ? (
            <Button type='link' danger>
              Deactivate
            </Button>
          ) : (
            <Button type='link'>Activate</Button>
          )}
        </Popconfirm>
      ),
    },
    {
      title: '',
      dataIndex: 'action2',
      hidden: !showPending,
      render: (_, row) => (
        <Popconfirm
          title={'Are you sure?'}
          okButtonProps={{ type: 'primary', danger: true }}
          okText='Confirm'
          onConfirm={async () => {
            await api.put('/api/admin/invite/remove', {
              inviteId: row.userId,
            });
            await getUsers();
          }}
        >
          <Button type='link' danger>
            Remove invite
          </Button>
        </Popconfirm>
      ),
    },
    {
      title: '',
      dataIndex: 'action3',
      hidden: !showPending,
      render: (_, row) => (
        <Popconfirm
          title={'Are you sure?'}
          okButtonProps={{ type: 'primary' }}
          okText='Confirm'
          onConfirm={async () => {
            await api.put('/api/admin/invite/resend', {
              inviteId: row.userId,
            });
            await getUsers();
          }}
        >
          <Button type='link'>Re-send invite</Button>
        </Popconfirm>
      ),
    },
  ];

  const [emailToInvite, setEmailToInvite] = useState('');
  const [role, setRole] = useState('user');

  const inviteUser = async () => {
    try {
      await api.post('/api/admin/users/invite', {
        invites: [{ email: emailToInvite, role }],
      });
      setEmailToInvite('');
      messageApi.success('User Invited');
    } catch (error) {
      messageApi.error('Error inviting user');
    }
  };

  useEffect(() => {
    if (!localStorage.getItem('underStandRole')) {
      setShowRoleHelpModal(true);
    }
  }, []);

  return (
    <div>
      {contextHolder}
      <Modal
        open={showRoleHelpModal}
        onOk={() => {
          setShowRoleHelpModal(false);
        }}
        onClose={() => {
          setShowRoleHelpModal(false);
        }}
        closable={false}
        footer={[
          <hr />,
          <Button
            key='submit'
            type='primary'
            onClick={() => {
              setShowRoleHelpModal(false);
              localStorage.setItem('underStandRole', 'true');
            }}
          >
            I understand
          </Button>,
        ]}
      >
        <Text>Roles:</Text>
        <ul>
          <li>
            Admin (Full Access)
            <ul>
              <li>
                <strong>What you can do:</strong> Manage users, oversee all
                projects, and adjust workspace settings.
              </li>
              <li>
                <strong>Ideal for: </strong> Those who need complete control
                over the entire workspace. (Workspace owners, administrators,
                etc.)
              </li>
            </ul>
          </li>
          <br />
          <li>
            Manager (Partial Access)
            <ul>
              <li>
                <strong>What you can do:</strong> Manage projects and delete
                tasks.
              </li>
              <li>
                <strong>Limitations:</strong> Cannot change workspace settings.
              </li>
              <li>
                <strong>Ideal for:</strong> Team leads who handle project
                coordination but don't need to alter workspace configurations.
                (Project managers, team leads, etc.)
              </li>
            </ul>
          </li>
          <br />
          <li>
            User (Limited Access)
            <ul>
              <li>
                <strong>What you can do:</strong> Create tasks, comment on
                tasks, and view projects.
              </li>
              <strong>Limitations:</strong> Cannot create or delete projects.
              <li>
                <strong>Ideal for:</strong> Those who need to collaborate on
                tasks but don't need to manage projects. (Developers,
                designers, QA, etc.)
              </li>
            </ul>
          </li>
        </ul>
      </Modal>
      <br />
      <Text>Invite user via email</Text>
      <div style={{ display: 'flex', gap: '.5rem' }}>
        <Input
          placeholder='Email to invite'
          type='email'
          value={emailToInvite}
          onChange={(e) => setEmailToInvite(e.target.value)}
        />
        <Space wrap>
          <Select
            defaultValue='user'
            style={{ width: 120 }}
            onChange={(selectedRole) => {
              setRole(selectedRole);
            }}
            options={[
              { value: 'user', label: 'User' },
              { value: 'manager', label: 'Manager' },
              { value: 'admin', label: 'Admin' },
            ]}
          />
        </Space>
        <Button
          type='primary'
          onClick={() => {
            inviteUser();
          }}
        >
          Invite
        </Button>
      </div>

      <div style={{ marginTop: '1rem' }}></div>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <div>
          <Text>Users</Text>
        </div>
        <div>
          <Space wrap>
            <Select
              defaultValue='active'
              size='small'
              style={{ width: 150 }}
              onChange={(selected) => {
                setShowPending(selected === 'pending');
              }}
              options={[
                { value: 'active', label: 'Active' },
                { value: 'pending', label: 'Pending Invites' },
              ]}
            />
            <Button
              onClick={() => {
                setShowRoleHelpModal(true);
              }}
              size='small'
              type='text'
            >
              <QuestionCircleOutlined />
            </Button>{' '}
          </Space>
        </div>
      </div>
      <div style={{ marginTop: '.5rem' }}></div>
      <Input.Search
        onSearch={setSearchTerm}
        allowClear
        placeholder='Search'
        style={{ marginBottom: '1rem' }}
      />

      <Table
        virtual
        size='small'
        columns={columns}
        dataSource={users.filter(
          (user: any) =>
            user.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase())
        )}
      />
    </div>
  );
}
