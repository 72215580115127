import { useEffect, useState } from 'react';
import { api } from '../../api';
import { useProjectStore } from '../../state/projectState';
import { useBoardStore } from '../../state/boardState';

import { Button, Input, Select, Skeleton, Space, Tooltip } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import CreateBoardModal from '../../modals/CreateBoard';
import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useMessage from 'antd/es/message/useMessage';
import { useUserStore } from '@/state/userState';
import useIsMobile from '@/hooks/useMobile';

export default function BoardSelector() {
  const navigate = useNavigate();
  const { projectKey, boardKey } = useParams();

  const [boardToEdit, setBoardToEdit] = useState(null) as any;
  const [messageApi, contextHolder] = useMessage();

  const setBoard = useBoardStore((state) => state.setBoard);
  const board = useBoardStore((state) => state.board);
  const projectStore = useProjectStore();

  const userStore = useUserStore();

  const [search, setSearch] = useState(null) as any;
  const [openCreateBoard, setOpenCreateBoard] = useState(false);

  async function getBoards() {
    const { data } = await api.get(`/api/projects/${projectKey}/boards`);
    return data;
  }

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['boards', projectKey],
    queryFn: getBoards,
  });

  useEffect(() => {
    if (data && data.length && !board) {
      if (boardKey) {
        const board = data.find((b: any) => b.key === +boardKey);
        if (board) {
          setBoard(board);
        }
      } else {
        setBoard(data[0]);
      }
    }
  }, [data]);


  const isMobile = useIsMobile();

  if (!projectStore.project) {
    return (
      <>
        <Text>Project not found</Text>
      </>
    );
  }


  if (isLoading) {
    return <Skeleton active />;
  }


  const size = isMobile ? 'small' : 'middle';

  if (!isLoading && data?.length === 0) {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <Text>Boards</Text>
          </div>

          {(userStore.user?.isAdmin || userStore.user?.isManager) && (
            <div style={{ marginTop: '.5rem' }}>
              <Button
                type='link'
                size={size}
                onClick={() => {
                  setOpenCreateBoard(true);
                }}
              >
                <PlusOutlined />
              </Button>
            </div>
          )}
        </div>
        <div>
          <Text>Create a board to get started</Text>
        </div>
        {openCreateBoard && (
          <CreateBoardModal
            board={boardToEdit}
            open={openCreateBoard}
            handleCancel={() => {
              setBoardToEdit(null);
              setOpenCreateBoard(false);
            }}
            handleOk={async () => {
              setBoardToEdit(null);
              setOpenCreateBoard(false);
              await refetch();
              if (boardToEdit) {
                messageApi.success('Board Updated');
                return;
              }
              messageApi.success('Board Created');
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem',
      }}
    >
      {contextHolder}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <Text>Boards</Text>
        </div>

        {(userStore.user?.isAdmin || userStore.user?.isManager) && (
          <div>
            <Button
              title='Create new board'
              size={size}
              type='link'
              onClick={() => {
                setOpenCreateBoard(true);
              }}
            >
              <PlusOutlined />
            </Button>
          </div>
        )}
      </div>
      <Input
        placeholder='Search'
        allowClear
        size={size}
        onChange={(e) => {
          if (e.target.value.trim() === '') {
            setSearch(null);
            return;
          }
          setSearch(e.target.value.trim());
        }}
      />
      <div style={{ height: 200, overflow: 'scroll' }}>
        <Space direction='vertical' style={{ width: '100%' }}>
          {data
            ?.filter((board: any) => {
              if (search === null) {
                return true;
              }
              return board?.label.toLowerCase().includes(search.toLowerCase());
            })
            .map((board: any) => {
              return (
                <div
                  key={board.id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '.3rem',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <Button
                      type={
                        boardKey
                          ? +board.key === +boardKey
                            ? 'primary'
                            : 'text'
                          : 'text'
                      }
                      style={{
                        width: '100%',
                        justifyContent: 'flex-start',
                      }}
                      size={size}
                      onClick={() => {
                        setBoard(board);
                        navigate(`/project/${projectKey}/board/${board.key}`);
                      }}
                    >
                      {board.label}
                    </Button>
                  </div>
                  <div>
                    {(userStore.user?.isAdmin || userStore.user?.isManager) && (
                      <Button
                        type='link'
                        size={size}
                        onClick={() => {
                          setBoardToEdit(board);
                          setOpenCreateBoard(true);
                        }}
                      >
                        <SettingOutlined />
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}
        </Space>
      </div>
      {openCreateBoard && (
        <CreateBoardModal
          board={boardToEdit}
          open={openCreateBoard}
          handleCancel={() => {
            setBoardToEdit(null);
            setOpenCreateBoard(false);
          }}
          handleOk={async () => {
            setBoardToEdit(null);
            setOpenCreateBoard(false);
            await refetch();
            if (boardToEdit) {
              messageApi.success('Board Updated');
              return;
            }
            messageApi.success('Board Created');
          }}
        />
      )}
    </div>
  );
}
