import { api } from '@/api';
import { Switch, Table, Tabs } from 'antd';
import { ColumnType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import Text from 'antd/es/typography/Text';

export const NotificationSettings = () => {
  const [slackEnabled, setSlackEnabled] = useState(true);

  const [slackOptionsEnabled, setSlackOptionsEnabled] = useState(true);
  const [inAppOptionsEnabled, setInAppOptionsEnabled] = useState(true);

  const [data, setData] = useState([]);

  const getSlackEnabled = async () => {
    const response = await api.get(`/api/integrations/slack/details`);
    setSlackEnabled(response.data.enabled);
  };

  const getNotificationSettings = async () => {
    const response = await api.get(`/api/notifications/settings`);
    setData(response.data);
  };

  useEffect(() => {
    getNotificationSettings();
    getSlackEnabled();
  }, []);

  const tableColumns: ColumnType<any>[] = [
    {
      title: 'Notifications',
      dataIndex: 'notification',
      key: 'notification',
      render(value, record, index) {
        return <div>
          <Text> {value}</Text>
          <br />
          <Text type='secondary'>
            <small>{record.description}</small>
          </Text>
        </div>;
      }
    },
    {
      title: 'In-App',
      dataIndex: 'in_app',
      key: 'in_app',
      render(value, record, index) {
        return (
          <Switch
            checked={record.key === '1' ? inAppOptionsEnabled : value}
            disabled={!inAppOptionsEnabled && record.key !== '1'}
            onChange={async (checked) => {
              if (record.key === '1') {
                setInAppOptionsEnabled(checked);
              }
              await api.put(`/api/notifications/settings`, {
                serviceKey: 'in_app',
                optionKey: record.key,
                enabled: checked,
              });
              await getNotificationSettings();
            }}
          />
        );
      },
    },
    slackEnabled
      ? {
        title: 'Slack',
        dataIndex: 'slack',
        key: 'slack',
        render(value, record, index) {
          return (
            <Switch
              checked={record.key === '1' ? slackOptionsEnabled : value}
              disabled={!slackOptionsEnabled && record.key !== '1'}
              onChange={async (checked) => {
                if (record.key === '1') {
                  setSlackOptionsEnabled(checked);
                }
                await api.put(`/api/notifications/settings`, {
                  serviceKey: 'slack',
                  optionKey: record.key,
                  enabled: checked,
                });
                await getNotificationSettings();
              }}
            />
          );
        },
      }
      : {},
  ];

  return <Table columns={tableColumns} dataSource={data} />;
};
