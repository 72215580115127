import { api } from '../../api';
import { useBoardStore } from '../../state/boardState';
import { useProjectStore } from '../../state/projectState';
import {
  Card,
  Checkbox,
  Divider,
  Input,
  Modal,
  Select,
  Slider,
  Table,
  message,
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Editor from '../../components/Editor/index3';
import { mutateContent } from '@/utils/uploadUtils';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { getTaskPriorityIcon, getTaskTypeIcon } from '@/utils';

export default function CreateTaskModalGlobal({
  showNewTaskForm,
  setShowNewTaskForm,
  onCreateTask,
  defaultProjectKey,
}: {
  showNewTaskForm: boolean;
  setShowNewTaskForm: (show: boolean) => void;
  onCreateTask: (task: any) => void;
  defaultProjectKey: string;
}) {
  const [messageApi, contextHolder] = message.useMessage();

  const [loadingCreateTask, setLoadingCreateTask] = useState(false);

  const [projectKey, setProjectKey] = useState(defaultProjectKey);
  const [boardKey, setBoardKey] = useState('');
  const [newTaskColumnId, setNewTaskColumnId] = useState('');
  const [newTaskLabel, setNewTaskLabel] = useState('');
  const [newTaskDescription, setNewTaskDescription] = useState('');
  const [newTaskAssignee, setNewTaskAssignee] = useState('');
  const [newTaskTypeId, setNewTaskTypeId] = useState('');
  const [newTaskPriorityId, setNewTaskPriorityId] = useState(
    'tdkcw9in2xgbq1lrpd4phsmk'
  );
  const [questId, setQuestId] = useState('NONE');
  const [experience, setExperience] = useState(50);
  const [dueDate, setDueDate]: any = useState(null);

  const [createMultiple, setCreateMultiple] = useState(false);
  const [keepProject, setKeepProject] = useState(true);
  const [keepBoard, setKeepBoard] = useState(true);

  const [keepStatus, setKeepStatus] = useState(true);
  const [keepType, setKeepType] = useState(true);
  const [keepAssignee, setKeepAssignee] = useState(true);
  const [keepLabel, setKeepLabel] = useState(true);
  const [keepDescription, setKeepDescription] = useState(true);
  const [keepPriority, setKeepPriority] = useState(true);
  const [keepQuest, setKeepQuest] = useState(true);
  const [keepDueDate, setKeepDueDate] = useState(true);

  const [projects, setProjects] = useState<any[]>([]);
  const [boards, setBoards] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [taskTypes, setTaskTypes] = useState<any[]>([]);
  const [priorities, setPriorities] = useState<any[]>([]);
  const [quests, setQuests] = useState<any[]>([]);

  const [recentlyCreatedTasks, setRecentlyCreatedTasks] = useState<any>([]);

  async function getProjects() {
    const response = await api.get(`/api/projects`);

    if (!defaultProjectKey && !projectKey) setProjectKey(response.data[0].key);

    setProjects(response.data);
  }

  async function getPriorities() {
    const response = await api.get(`/api/priority`);
    setPriorities(response.data);
  }

  async function getBoards() {
    const response = await api.get(`/api/projects/${projectKey}/boards`);
    setBoardKey(response.data[0].key);
    setBoards(response.data);
  }

  async function getQuests() {
    const response = await api.get(
      `/api/projects/${projectKey}/quests?filter=all`
    );
    setQuests([{ id: 'NONE', label: 'No Quest Selected' }, ...response.data]);
  }

  async function getColumns() {
    const response = await api.get(
      `/api/projects/${projectKey}/boards/${boardKey}/columns?withTasks=false`
    );

    setNewTaskColumnId(response.data[0].id);

    setColumns([
      ...response.data.map((column: any) => ({
        id: column.id,
        label: column.label,
      })),
    ]);
  }

  async function getUsers() {
    const response = await api.get(`/api/projects/${projectKey}/users`);
    setNewTaskAssignee('no-user');
    setUsers([
      { id: 'no-user', label: 'No Assignee Selected' },
      ...response.data.map((user: any) => ({
        id: user.id,
        label: user.fullName,
      })),
    ]);
  }

  async function getTaskTypes() {
    const response = await api.get(`/api/task-types`);
    setNewTaskTypeId(response.data[0].id);
    setTaskTypes([
      ...response.data.map((taskType: any) => ({
        id: taskType.id,
        label: taskType.label,
        description: taskType.description,
      })),
    ]);
  }

  useEffect(() => {
    getTaskTypes();
    getPriorities();
  }, []);

  useEffect(() => {
    getUsers();
    getProjects();
  }, [projectKey]);

  useEffect(() => {
    if (projectKey) {
      getBoards();
      getQuests();
    }
  }, [projectKey]);

  useEffect(() => {
    if (projectKey && boardKey) {
      getColumns();
    }
  }, [projectKey, boardKey]);

  const labelRef: any = useRef(null);

  useEffect(() => {
    if (labelRef.current) {
      labelRef.current.focus();
    }
  }, [loadingCreateTask]);

  const createTask = async () => {
    setLoadingCreateTask(true);
    try {
      const response = await api.post(`/api/columns/${newTaskColumnId}/tasks`, {
        label: newTaskLabel,
        description: await mutateContent(newTaskDescription),
        columnId: newTaskColumnId,
        assigneeId: newTaskAssignee === 'no-user' ? null : newTaskAssignee,
        taskTypeId: newTaskTypeId,
        priorityId: newTaskPriorityId,
        questId: questId === 'NONE' ? null : questId,
        parentId: null,
        dueDate: dueDate ? dueDate.format('YYYY-MM-DD') : null,
      });

      if (createMultiple) {
        if (!keepStatus) setNewTaskColumnId('');
        if (!keepType) setNewTaskTypeId('');
        if (!keepAssignee) setNewTaskAssignee('');
        if (!keepLabel) setNewTaskLabel('');
        if (!keepDescription) setNewTaskDescription('');
        if (!keepPriority) setNewTaskPriorityId('tdkcw9in2xgbq1lrpd4phsmk');
        if (!keepQuest) setQuestId('');
        if (!keepDueDate) setDueDate(null);

        const task = {
          key: response.data,
          label: newTaskLabel,
          status: columns.find((column) => column.id === newTaskColumnId).label,
          board: boards.find((board) => board.key === boardKey).label,
          questId: questId,
          dueDate: dueDate,
          priority: priorities.find(
            (priority) => priority.id === newTaskPriorityId
          ).label,
          taskTypeLabel: taskTypes.find(
            (taskType) => taskType.id === newTaskTypeId
          ).label,
          assigneeFullName:
            newTaskAssignee === 'no-user'
              ? 'No Assignee'
              : users.find((user) => user.id === newTaskAssignee).label,
        };
        setRecentlyCreatedTasks([task, ...recentlyCreatedTasks]);
      } else {
        setNewTaskLabel('');
        setNewTaskColumnId('');
        setNewTaskAssignee('');
        setNewTaskTypeId('');
        setNewTaskDescription('');
        setQuestId('');
        setExperience(50);
        setShowNewTaskForm(false);
      }
    } catch (error: any) {
      if (error.resopnse.data.message) {
        messageApi.error(error.resopnse.data.message);
        setLoadingCreateTask(false);
        return;
      }
      messageApi.error('Failed to create task');
      setLoadingCreateTask(false);
      return;
    }

    messageApi.success('Task Created');
    onCreateTask({});
    setLoadingCreateTask(false);
  };

  const inputVariant = 'filled';

  const size = undefined;

  return (
    <Modal
      open={showNewTaskForm}
      width={1100}
      title='Create Task'
      style={{ top: 20 }}
      okButtonProps={{
        disabled: loadingCreateTask,
        loading: loadingCreateTask,
      }}
      onCancel={() => {
        setShowNewTaskForm(false);
      }}
      onOk={async () => {
        createTask();
      }}
      okText='Create'
    >
      <div>
        {contextHolder}

        <div style={{ display: 'flex', gap: '1rem' }}>
          {/* <div>
          <p>Complexity / Points</p>
          <div
            style={{ width: 700, marginLeft: '.8rem', marginBottom: '3rem' }}
          >
            <Slider
              value={experience}
              onChange={(value) => setExperience(value)}
              max={150}
              step={10}
              marks={{
                0: 'None',
                20: 'Easy',
                50: 'Normal',
                100: 'Hard',
                150: 'Expert',
              }}
            />
          </div>
        </div> */}
          <div className='left' style={{ flex: 5 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
              }}
            >
              <div>
                <Text>Project</Text>
                <Select
                  title='Project'
                  placeholder='Project'
                  style={{ width: '100%', minWidth: 10 }}
                  size={size}
                  value={projectKey}
                  onChange={(value) => setProjectKey(value)}
                  variant={inputVariant}
                  showSearch
                  filterOption={(input, option: any) => {
                    return (
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase().trim()) >= 0
                    );
                  }}
                >
                  {projects.map((project) => (
                    <Select.Option key={project.id} value={project.key}>
                      {project.label}
                    </Select.Option>
                  ))}
                </Select>
                <div>
                  {createMultiple && (
                    <Checkbox
                      title='Keep'
                      checked={keepProject}
                      defaultChecked={keepProject}
                      onChange={(e) => {
                        setKeepProject(e.target.checked);
                      }}
                    >
                      Keep for next task
                    </Checkbox>
                  )}
                </div>
              </div>
              <div>
                <Text>Board</Text>
                <Select
                  title='Board'
                  placeholder='Board'
                  style={{ width: '100%', minWidth: 10 }}
                  size={size}
                  value={boardKey}
                  variant={inputVariant}
                  onChange={(value) => setBoardKey(value)}
                >
                  {boards.map((board) => (
                    <Select.Option key={board.id} value={board.key}>
                      {board.label}
                    </Select.Option>
                  ))}
                </Select>
                <div>
                  {createMultiple && (
                    <Checkbox
                      title='Keep'
                      checked={keepBoard}
                      defaultChecked={keepBoard}
                      onChange={(e) => {
                        setKeepBoard(e.target.checked);
                      }}
                    >
                      Keep for next task
                    </Checkbox>
                  )}
                </div>
              </div>

              <div>
                <Text>Status</Text>

                <Select
                  title='Status'
                  placeholder='Status'
                  style={{ width: '100%', minWidth: 10 }}
                  size={size}
                  value={newTaskColumnId}
                  variant={inputVariant}
                  onChange={(value) => setNewTaskColumnId(value)}
                >
                  {columns.map((column) => (
                    <Select.Option key={column.id} value={column.id}>
                      {column.label}
                    </Select.Option>
                  ))}
                </Select>
                <div>
                  {createMultiple && (
                    <Checkbox
                      title='Keep'
                      checked={keepStatus}
                      defaultChecked={keepStatus}
                      onChange={(e) => {
                        setKeepStatus(e.target.checked);
                      }}
                    >
                      Keep for next task
                    </Checkbox>
                  )}
                </div>
              </div>
            </div>

            <p>Label</p>

            <Input
              placeholder='Label'
              variant={inputVariant}
              ref={labelRef}
              value={newTaskLabel}
              disabled={loadingCreateTask}
              onChange={(e) => setNewTaskLabel(e.target.value)}
              onPressEnter={async () => {
                await createTask();
              }}
            />
            <div>
              {createMultiple && (
                <Checkbox
                  title='Keep'
                  checked={keepLabel}
                  defaultChecked={keepLabel}
                  onChange={(e) => {
                    setKeepLabel(e.target.checked);
                  }}
                >
                  Keep for next task
                </Checkbox>
              )}
            </div>

            {/* <EditorComponent
          defaultContent={newTaskDescription}
          onChange={setNewTaskDescription}
        /> */}

            {/* <Editor2 onChange={setNewTaskDescription} /> */}
            <br />
            <Card>
              <Text>Description</Text>
              <Divider
                style={{
                  margin: '.5rem 0',
                }}
              />
              <Editor
                onChange={setNewTaskDescription}
                projectKey={projectKey}
                defaultContent={null}
              />

              <div>
                {createMultiple && (
                  <Checkbox
                    title='Keep'
                    checked={keepDescription}
                    defaultChecked={keepDescription}
                    onChange={(e) => {
                      setKeepDescription(e.target.checked);
                    }}
                  >
                    Keep for next task
                  </Checkbox>
                )}
              </div>
            </Card>

            <p>Multiple</p>
            <div>
              <Checkbox
                checked={createMultiple}
                onChange={(e) => {
                  setCreateMultiple(e.target.checked);
                }}
              >
                Create Multiple
              </Checkbox>
            </div>
            {createMultiple && (
              <Table
                dataSource={recentlyCreatedTasks}
                rowKey='id'
                pagination={false}
                style={{ marginTop: '1rem' }}
              >
                <Table.Column title='Key' dataIndex='key' />
                <Table.Column title='Board' dataIndex='board' />
                <Table.Column title='Label' dataIndex='label' />
                <Table.Column title='Status' dataIndex='status' />
                <Table.Column title='Type' dataIndex='taskTypeLabel' />
                <Table.Column title='Priority' dataIndex='priority' />
                <Table.Column title='Assignee' dataIndex='assigneeFullName' />
              </Table>
            )}
          </div>
          <div className='right' style={{ flex: 2 }}>
            <div
              style={{
                display: 'flex',
                gap: '1rem',
                flexDirection: 'column',
              }}
            >
              <div>
                <Text>Type</Text>
                <Select
                  placeholder='Type'
                  style={{ width: '100%', minWidth: 10 }}
                  size={size}
                  value={newTaskTypeId}
                  variant={inputVariant}
                  onChange={(value) => setNewTaskTypeId(value)}
                  labelRender={(option: any) => (
                    <>
                      {getTaskTypeIcon(option.label?.toLowerCase() as any)}{' '}
                      {option.label}
                    </>
                  )}
                  optionRender={(option: any) => (
                    <div style={{ display: 'flex', gap: '.3rem' }}>
                      {getTaskTypeIcon(option.label?.toLowerCase() as any)}{' '}
                      {option.label}
                    </div>
                  )}
                  options={taskTypes.map((taskType) => ({
                    value: taskType.id,
                    label: taskType.label,
                    title: taskType.description,
                  }))}
                />
                <div>
                  {createMultiple && (
                    <Checkbox
                      title='Keep'
                      checked={keepType}
                      defaultChecked={keepType}
                      onChange={(e) => {
                        setKeepType(e.target.checked);
                      }}
                    >
                      Keep for next task
                    </Checkbox>
                  )}
                </div>
              </div>

              <div>
                <Text>Priority</Text>
                <Select
                  placeholder='Priority'
                  style={{ width: '100%', minWidth: 10 }}
                  value={newTaskPriorityId}
                  size={size}
                  variant={inputVariant}
                  onChange={(value) => setNewTaskPriorityId(value)}
                  labelRender={(value: any) => {
                    return (
                      <>
                        {getTaskPriorityIcon(value.label?.toLowerCase() as any)}{' '}
                        {value.label}
                      </>
                    );
                  }}
                  optionRender={(option: any) => (
                    <div style={{ display: 'flex', gap: '.3rem' }}>
                      {getTaskPriorityIcon(option.label?.toLowerCase() as any)}{' '}
                      {option.label}
                    </div>
                  )}
                  options={priorities.map((priority) => ({
                    value: priority.id,
                    label: priority.label,
                  }))}
                />
                <div>
                  {createMultiple && (
                    <Checkbox
                      title='Keep'
                      checked={keepPriority}
                      defaultChecked={keepPriority}
                      onChange={(e) => {
                        setKeepPriority(e.target.checked);
                      }}
                    >
                      Keep for next task
                    </Checkbox>
                  )}
                </div>
              </div>

              <div>
                <Text>Quest</Text>
                <Select
                  placeholder='Quest'
                  style={{ width: '100%', minWidth: 100 }}
                  value={questId}
                  variant={inputVariant}
                  size={size}
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option?.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase().trim()) >= 0
                    );
                  }}
                  onChange={(value) => setQuestId(value)}
                  options={quests.map((quest) => ({
                    value: quest.id,
                    label: quest.label,
                  }))}
                />
                <div>
                  {createMultiple && (
                    <Checkbox
                      title='Keep'
                      checked={keepPriority}
                      defaultChecked={keepPriority}
                      onChange={(e) => {
                        setKeepQuest(e.target.checked);
                      }}
                    >
                      Keep for next task
                    </Checkbox>
                  )}
                </div>
              </div>

              <div>
                <Text>Assignee</Text>

                <Select
                  placeholder=' Assignee'
                  style={{ width: '100%', minWidth: 10 }}
                  value={newTaskAssignee}
                  size={size}
                  showSearch
                  variant={inputVariant}
                  filterOption={(input, option: any) => {
                    return (
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase().trim()) >= 0
                    );
                  }}
                  onChange={(value) => setNewTaskAssignee(value)}
                >
                  {users.map((user) => (
                    <Select.Option key={user.id} value={user.id}>
                      {user.label}
                    </Select.Option>
                  ))}
                </Select>
                <div>
                  {createMultiple && (
                    <Checkbox
                      title='Keep'
                      checked={keepAssignee}
                      defaultChecked={keepAssignee}
                      onChange={(e) => {
                        setKeepAssignee(e.target.checked);
                      }}
                    >
                      Keep for next task
                    </Checkbox>
                  )}
                </div>
              </div>

              <div>
                <Text>Due Date</Text>
                <div>
                  <DatePicker
                    style={{ width: '100%' }}
                    variant={inputVariant}
                    format={'DD MMM YYYY'}
                    value={dueDate}
                    onChange={(value) => setDueDate(value)}
                    showNow={false}
                    presets={[
                      {
                        label: 'Today',
                        value: dayjs(),
                      },
                      {
                        label: 'Tomorrow',
                        value: dayjs().add(1, 'day'),
                      },
                      {
                        label: 'Next week',
                        value: dayjs().add(1, 'week'),
                      },
                      {
                        label: '2 weeks',
                        value: dayjs().add(2, 'week'),
                      },
                      {
                        label: '1 month',
                        value: dayjs().add(1, 'month'),
                      },
                    ]}
                  />
                  {dueDate && (
                    // show relative time
                    <Text type='secondary'>
                      Due in{' '}
                      {dayjs(dueDate.endOf('day')).diff(dayjs(), 'day') > 0
                        ? dayjs(dueDate.endOf('day')).diff(dayjs(), 'day') +
                        ' days'
                        : 'Today'}
                    </Text>
                  )}
                  <div>
                    {createMultiple && (
                      <Checkbox
                        title='Keep'
                        checked={keepDueDate}
                        defaultChecked={keepDueDate}
                        onChange={(e) => {
                          setKeepDueDate(e.target.checked);
                        }}
                      >
                        Keep for next task
                      </Checkbox>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
