import { Card, Table } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { api } from '@/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { RelativeTime } from '@/components/RelativeTime';
import { getTaskPriorityIcon, getTaskTypeIcon } from '@/utils';

export default function Watched() {
  const navigate = useNavigate();

  const getPointlog = async () => {
    const tasks = await api.get('/api/me/watched-tasks');

    return tasks.data.tasks;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['watchedTasks'],
    queryFn: getPointlog,
  });

  const queryClient = useQueryClient();

  return (
    <Table
      loading={isLoading}
      size='small'
      // style={{ width: '100%' }}
      pagination={false}
      bordered
      scroll={{ y: '75vh' }}
      title={() => <Text strong>Watched tasks</Text>}
      columns={[
        {
          title: 'Key',
          dataIndex: 'taskKey',
          key: 'taskKey',
          sorter: (a: any, b: any) => a.taskKey.localeCompare(b.taskKey),
        },
        {
          title: 'Label',
          dataIndex: 'taskLabel',
          key: 'taskLabel',
        },
        {
          title: 'Due In',
          dataIndex: 'dueDate',
          key: 'dueDate',
          defaultSortOrder: 'ascend',
          sorter: (a: any, b: any) =>
            new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime(),
          render: (dueDate: any) =>
            dueDate ? <RelativeTime date={dueDate} /> : '/',
        },
        {
          title: 'Status',
          dataIndex: 'columnLabel',
          key: 'columnLabel',
          sorter: (a: any, b: any) =>
            a.columnLabel.localeCompare(b.columnLabel),
        },
        {
          title: 'In Status Since',
          dataIndex: 'transferedAt',
          key: 'transferedAt',
          sorter: (a: any, b: any) =>
            new Date(a.transferedAt).getTime() -
            new Date(b.transferedAt).getTime(),
          render: (transferedAt: any) => (
            <RelativeTime date={transferedAt} showHoursAndMinutes />
          ),
        },
        {
          title: 'Priority',
          dataIndex: 'priority',
          key: 'priority',
          sorter: (a: any, b: any) => a.priority.localeCompare(b.priority),
          render: (priority: any) => (
            <>{getTaskPriorityIcon(priority.toLowerCase() as any)}</>
          ),
        },
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
          sorter: (a: any, b: any) => a.type.localeCompare(b.type),
          render: (type: any) => (
            <>{getTaskTypeIcon(type.toLowerCase() as any)}</>
          ),
        },
        {
          title: 'Assigned',
          dataIndex: 'assignedAt',
          key: 'assignedAt',
          sorter: (a: any, b: any) =>
            new Date(a.assignedAt).getTime() - new Date(b.assignedAt).getTime(),
          render: (assignedAt: any) => <RelativeTime date={assignedAt} />,
        },
        {
          title: '',
          dataIndex: 'taskId',
          key: 'taskId',
          render: (value: any) => (
            <a title='Look other way' onClick={async (e) => {
              e.stopPropagation();
              await api.put(`/api/tasks/${value}/watch`);
              queryClient.setQueryData(['watchedTasks'], (oldData: any) => {
                return oldData.filter((task: any) => task.taskId !== value)
              });
            }}>Stop watching</a>
          ),

        }
      ]}
      onRow={(record: any, rowIndex) => {
        return {
          onClick: (event) => {
            navigate(`?openTask=${record.taskKey}`);
          },
        };
      }}
      dataSource={data}
    />
  );
}
