import React, { useEffect, useState } from 'react';
import TipTapEditor from '../Editor/index3';
import { Button, Divider, Popconfirm, Spin, Tooltip, message } from 'antd';
import { api } from '../../api';
import Text from 'antd/es/typography/Text';
import { useSearchParams } from 'react-router-dom';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import UserAvatar from '../UserAvatar';
import Reactions from './Reactions';
import { mutateContent } from '@/utils/uploadUtils';
import { RelativeTime } from '../RelativeTime';
import { useUserStore } from '@/state/userState';

const Comment = React.memo(
  ({
    comment,
    onDelete,
    projectKey,
  }: {
    comment: any;
    onDelete: any;
    projectKey: string;
  }) => {
    const [editMode, setEditMode] = React.useState(false);
    const messageApi = message.useMessage()[0];
    const [searchParams, setSearchParams] = useSearchParams();
    const [content, setContent] = useState(comment.content);
    const [isUpdating, setIsUpdating] = useState(false);

    const [highlighted, setHighlighted] = useState(false);

    const userStore = useUserStore();

    const scrollRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
      setTimeout(() => {
        if (searchParams.get('commentId') === comment.id) {
          scrollRef.current?.scrollIntoView(true);
          setHighlighted(true);
        }
      }, 500);
    }, [searchParams, scrollRef]);

    const deleteComment = React.useCallback(async () => {
      await api.delete(`/api/comments/${comment.id}`);
      messageApi.success('Comment deleted');
      onDelete();
    }, [comment.id, messageApi, onDelete]);

    const updateComment = React.useCallback(
      async (content: string) => {
        try {

          setIsUpdating(true);
          const ct = await mutateContent(content);
          await api.put(`/api/comments/${comment.id}`, {
            content: ct,
          });
          setIsUpdating(false);
          setContent(ct);
          messageApi.success('Comment updated');
          setEditMode(false);
        } catch (e) {
          setIsUpdating(false);
          throw e;
        }
      },
      [comment.id, messageApi, setEditMode]
    );

    return (
      <div
        style={{
          margin: '.3rem',
          borderRadius: '8px',
          padding: '3px',
          border: highlighted ? '1px solid #1890ff' : 'none',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            ref={scrollRef}
            id={comment.id}
            style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
          >
            <div
              style={{ display: 'flex', alignItems: 'center', gap: '.3rem' }}
            >
              <UserAvatar token={comment.userAvatar} size={30} />
              <strong>{comment.userName}</strong>
            </div>
            <Text type='secondary'>
              <RelativeTime date={comment.createdAt} showHoursAndMinutes />
            </Text>
            {comment.updatedAt && (
              <Text type='secondary'>
                edited{' '}
                <RelativeTime date={comment.updatedAt} showHoursAndMinutes />
              </Text>
            )}
          </div>
          {comment.userId === userStore.user?.id && (
            <div>
              <Tooltip title={editMode ? 'Cancel Edit' : 'Edit'}>
                <Button
                  type='link'
                  size='small'
                  onClick={() => setEditMode((prev) => !prev)}
                >
                  {editMode ? 'Cancel' : <EditFilled />}
                </Button>
              </Tooltip>
              <Tooltip title='Delete'>
                <Popconfirm
                  okType='danger'
                  title='Are you sure?'
                  onConfirm={deleteComment}
                >
                  <Button danger type='link' size='small'>
                    <DeleteFilled />
                  </Button>
                </Popconfirm>
              </Tooltip>
            </div>
          )}
        </div>
        <Spin spinning={isUpdating}>
          <TipTapEditor
            editable={editMode}
            onSave={updateComment}
            defaultContent={content}
            projectKey={projectKey}
          />
        </Spin>
        <Reactions
          commentId={comment.id}
          commentReactions={comment.reactions}
        />
        <Divider style={{ margin: '.5rem 0' }} />
      </div>
    );
  }
);
export default Comment;
