import { api } from '@/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Badge,
  Button,
  Card,
  Dropdown,
  Empty,
  Menu,
  Skeleton,
  Tabs,
  TabsProps,
  notification,
} from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import RelativeTime from 'react-relative-time';

import Text from 'antd/es/typography/Text';
import UserAvatar from '../UserAvatar';
import { BellOutlined, InfoCircleFilled } from '@ant-design/icons';
import dayjs from 'dayjs';
import { RelativeTime } from '../RelativeTime';
import { sseClient } from '@/api/realtime';

// Function to convert UTC to local time
function convertUTCToLocal(utcDateTime: string) {
  return dayjs(utcDateTime).toDate();
}

const NotificationDropdown = ({
  updatePoints,
}: {
  updatePoints: () => void;
}) => {
  const queryClient = useQueryClient();
  const [notificationType, setNotificationType] = useState('unread');
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  const [limitUnread, setUnreadLimit] = useState(10);
  const [limitRead, setLReadLimit] = useState(10);

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const getNotifications = async () => {
    const response = await api.get(
      `/api/notifications?type=${notificationType}&limit=${notificationType === 'unread' ? limitUnread : limitRead
      }`
    );

    setUnreadNotificationsCount(response.data.unreadCount);

    return response.data.notifications;
  };

  const [notificationApi, contextHolder] = notification.useNotification();

  const handleNewNotification = (event: any) => {
    const newEvent = JSON.parse(JSON.parse(event.data));

    notificationApi.open({
      key: newEvent.id,
      message: newEvent.sender,
      type: 'info',
      showProgress: true,
      pauseOnHover: true,
      description: (
        <div>
          <Text>{newEvent.content}</Text>
          <br />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {newEvent.link && (
              <Button
                type='link'
                size='small'
                onClick={async () => {
                  navigate(newEvent.link);
                  await setRead(newEvent.id);
                  notificationApi.destroy(newEvent.id);
                }}
              >
                More Details
              </Button>
            )}
            <Button
              size='small'
              type='link'
              onClick={async () => {
                await setRead(newEvent.id);
                notificationApi.destroy(newEvent.id);
              }}
            >
              Mark as Read
            </Button>
          </div>
        </div>
      ),
    });
    queryClient.setQueryData(['notifications', 'unread'], (oldData: any) => {
      return [newEvent, ...oldData];
    });
    setUnreadNotificationsCount((prev) => prev + 1);
  };

  useEffect(() => {
    sseClient.addEventListener('notification', handleNewNotification);
    return () => {
      sseClient.removeEventListener('notification', handleNewNotification);
    };
  }, []);

  const {
    data: notifications,
    isLoading: notificationsLoading,
    isFetching: notificationsFetching,
    refetch: notificationRefetch,
  } = useQuery({
    queryKey: ['notifications', notificationType],
    queryFn: getNotifications,
  });

  const setRead = async (id: string) => {
    await api.post(`/api/notifications/${id}/read`);
    await notificationRefetch();
  };

  const groupedByDate = (notifications: any): {} => {
    return notifications.reduce((acc: any, notification: any) => {
      const date = dayjs(notification.createdAt).format('DD.MM.YYYY');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(notification);
      return acc;
    }, {});
  };

  const grouped: any = groupedByDate(notifications ?? []);

  const items: TabsProps['items'] = [
    {
      key: 'unread',
      label: 'New',
      children: (
        <div>
          <div style={{ width: '100%', textAlign: 'right' }}>
            {!notificationsLoading &&
              notifications?.filter((n: any) => n.seen === 0).length > 0 && (
                <Button
                  onClick={async () => {
                    await setRead('all');
                  }}
                  size='small'
                >
                  Mark all read
                </Button>
              )}
          </div>
          <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
            {notificationsLoading && (
              <div>
                <Skeleton active />
                <Skeleton active />
              </div>
            )}
            {!notificationsLoading &&
              notifications?.filter((n: any) => n.seen === 0).length === 0 && (
                <Empty />
              )}

            {grouped &&
              Object.keys(grouped).map((date) => (
                <div key={date}>
                  <Text type='secondary'>{date}</Text>
                  {grouped[date].map((notification: any) => (
                    <div
                      onClick={() => {
                        setOpen(false);
                        setRead(notification.id);
                      }}
                      key={notification.id}
                      style={{ margin: '.5rem 0' }}
                    >
                      <Link to={notification.link}>
                        <Card size='small'>
                          <div
                            style={{
                              display: 'flex',
                              gap: '.5rem',
                              alignItems: 'center',
                              width: '100%',
                            }}
                          >
                            {notification.senderAvatar ? (
                              <div>
                                <UserAvatar
                                  token={notification.senderAvatar}
                                  size={40}
                                />
                              </div>
                            ) : (
                              <InfoCircleFilled style={{ fontSize: 40 }} />
                            )}
                            <div style={{ width: '100%' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}
                              >
                                <div>
                                  <strong>
                                    {notification.sender ?? 'Info'}{' '}
                                  </strong>
                                </div>
                                <div>
                                  <Text type='secondary'>
                                    <RelativeTime
                                      date={notification.createdAt}
                                    />
                                  </Text>
                                </div>
                              </div>
                              <div style={{ marginTop: '.5rem' }}>
                                {notification.content}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              width: '100%',
                              textAlign: 'right',
                            }}
                          >
                            <Button
                              type='link'
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setRead(notification.id);
                              }}
                              size='small'
                            >
                              Mark read
                            </Button>
                          </div>
                        </Card>
                      </Link>
                    </div>
                  ))}
                </div>
              ))}

            {notifications?.filter((n: any) => n.seen === 0).length >= 10 && (
              <div
                style={{ textAlign: 'center' }}
                onClick={async () => {
                  setUnreadLimit(limitUnread + 10);
                  await notificationRefetch();
                }}
              >
                <Button loading={notificationsFetching} size='small'>
                  Load More
                </Button>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      key: 'read',
      label: 'Old',
      children: (
        <div style={{ maxHeight: '50vh', overflow: 'auto' }}>
          {notificationsLoading && (
            <div>
              <Skeleton active />
              <Skeleton active />
            </div>
          )}

          {!notificationsLoading &&
            notifications?.filter((n: any) => n.seen === 1).length === 0 && (
              <Empty />
            )}

          {grouped &&
            Object.keys(grouped).map((date) => (
              <div key={date}>
                <Text type='secondary'>{date}</Text>
                {grouped[date].map((notification: any) => (
                  <div
                    onClick={() => {
                      setOpen(false);
                    }}
                    key={notification.id}
                    style={{ margin: '.5rem 0' }}
                  >
                    <Link to={notification.link}>
                      <Card size='small'>
                        <div
                          style={{
                            display: 'flex',
                            gap: '.5rem',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          {notification.senderAvatar ? (
                            <div>
                              <UserAvatar
                                token={notification.senderAvatar}
                                size={40}
                              />
                            </div>
                          ) : (
                            <InfoCircleFilled style={{ fontSize: 40 }} />
                          )}
                          <div style={{ width: '100%' }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                              }}
                            >
                              <div>
                                <strong>
                                  {notification.sender ?? 'Info'}{' '}
                                </strong>
                              </div>
                              <div>
                                <Text type='secondary'>
                                  <RelativeTime date={notification.createdAt} />
                                </Text>
                              </div>
                            </div>
                            <div style={{ marginTop: '.5rem' }}>
                              {notification.content}
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Link>
                  </div>
                ))}
              </div>
            ))}

          {notifications?.filter((n: any) => n.seen === 1).length >= 10 && (
            <div
              style={{ textAlign: 'center' }}
              onClick={async () => {
                setLReadLimit(limitRead + 10);
                await notificationRefetch();
              }}
            >
              <Button loading={notificationsFetching} size='small'>
                Load More
              </Button>
            </div>
          )}
        </div>
      ),
    },
  ];

  const menu = useMemo(
    () => (
      <Menu style={{ minWidth: '500px', maxWidth: '500px', padding: '8px' }}>
        <Text type='secondary'>Notifications from last 30 days</Text>
        <Tabs
          defaultActiveKey={notificationType}
          type="card"
          items={items}
          activeKey={notificationType}
          onChange={(e) => {
            setNotificationType(e);
          }}
        />
      </Menu>
    ),
    [items, notificationType]
  );

  return (
    <div>
      {contextHolder}
      <Button type='text'>
        <Dropdown
          overlay={menu}
          open={open}
          onOpenChange={(open) => {
            if (!open) {
              setNotificationType('unread');
              setOpen(false);
            } else {
              setOpen(true);
            }
          }}
          trigger={['click']}
        >
          <Badge count={unreadNotificationsCount}>
            <BellOutlined style={{ fontSize: '25px' }} />
          </Badge>
        </Dropdown>
      </Button>
    </div>
  );
};

export default NotificationDropdown;
